import React from "react"
import TechSoftLayout from "../../../components/techSoftLayout/techSoftLayout.js"

const PiacereBarbecue = () => {
  return (
    <TechSoftLayout
      title="Rete WiFi &#10;Città di Riccione"
      descriptionType="customAndWifi"
      metaDescription="Per la Città di Riccione ACME ha realizzato l’infrastruttura WiFi e garantito servizi di autenticazione clienti, connessione."
      contents={[{
        date:`Committente: Comune di Riccione
        Anno: 2016 - 2022
        
        `,
        text: `
        Riccione diventa la prima città interamente cablata grazie ad una
        rete WiFi gratuita che copre tutto il lungomare e le principali vie
        interne della movida (V.le Ceccarini e V.le Dante). Il tutto per una
        copertura totale del servizio lunga 6 km.
        
        Non più isole WiFi con molteplici access point, ma un’unica area
        nella quale spostarsi senza perdita di segnale, grazie al cablaggio
        di apparati performanti. L’erogazione continua del servizio wireless
        si estende da nord a sud sul lungomare ed è fruibile senza perdita
        di connessione o richiesta di ulteriori autenticazioni. L’accesso
        alla navigazione si realizza attraverso il sistema Connect@You di
        proprietà della ACME Produzioni Srl, un gateway che permette
        l’autenticazione alla rete attraverso differenti modalità.`,
        image: "riccione.png"
        },
        {
        text: {
            partOne: `Esigenze del cliente
              
            • Realizzare una rete WiFi per la copertura di tutto il lungomare e le principali vie interne
            • Rendere estremamente semplice e veloce la procedura di autenticazione per gli utenti
            • Analisi della tipologia di utenti che frequentano la città di Riccione`,
            partTwo: `Le nostre soluzioni
              
            • Realizzazione infrastruttura rete WiFi per gli utenti
            • Captive portal Connect@You per autenticazione degli utenti
            • Attivazione del servizio form dinamico per profilazione target utenti
            • Fornitura connettività dedicata (n. 5 punti di distribuzione segnali)
            • Servizio di assistenza`
          }
        }
      ]}
    />
  )
}

export default PiacereBarbecue
