import "./src/assets/styles/global.scss"
import React from "react"
import { ThemeProvider } from "./src/context/ThemeContext"
import Layout from "./src/components/layout/layout.js"
//import { Location } from "@reach/router"

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>
    <Layout>{element}</Layout>
  </ThemeProvider>
)
