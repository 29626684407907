import React from "react"
import EntertainmentLayout from "../../../components/entertainmentLayout/entertainmentLayout.js"

const Allevi = () => {
  return (
    <EntertainmentLayout
      category="Segreteria Organizzativa"
      title="Giovanni Allevi &#10;Alien World Tour"
      bannerImage="allevi.png"
      metaDescription="Giovanni Allevi riparte da Siracusa, dopo il disco d’oro, con “Alien World Tour 2011” tenutosi nei più suggestivi castelli, anfiteatri e piazze d'Italia."
      description="Giovanni Allevi riparte da Siracusa con “Alien World Tour 2011” al
      quale hanno fatto da cornice i più suggestivi castelli, anfiteatri e
      piazze d'Italia."
      contents={[{
        date:`Agosto 2011 Spoleto, 
        Piazza Duomo 
        `,        
        text:`Dopo il successo ottenuto in primavera nei principali teatri
            d’Italia, registrando il tutto esaurito, Alien World Tour del
            compositore, pianista e direttore d’orchestra Giovanni Allevi,
            iniziato in California e proseguito in Giappone, “atterra” nei più
            suggestivi luoghi storici e prestigiosi d'Italia, a partire dal
            Castello di Maniace a Siracusa. 
            
            La tournée estiva vede l’artista
            impegnato in concerti per piano solo in cui esegue, oltre ai suoi
            più grandi successi, i brani di “Alien” disco d’oro.  
                      
            “Gli Alieni siamo noi che con la nostra sensibilità, cerchiamo lampi
            di poesia tra le pieghe dell'esistenza quotidiana. Rifiutando
            l'omologazione, affermiamo con delicatezza la nostra unicità,
            facendo della vita un'opera d'arte. È la musica che ci permette di
            guardare il mondo con occhi nuovi, tanto da riscoprire l'incanto in
            ciò che ci circonda, fino a sentirci alieni circondati da alieni.”
            Giovanni Allevi`,
        //image: "alleviDes.jpg"
       }]}  
    />
  )
}

export default Allevi
