import React from "react"
import TechSoftLayout from "../../../components/techSoftLayout/techSoftLayout.js"

const PaneNostrum = () => {
  return (
    <TechSoftLayout
      title="Pane Nostrum"
      descriptionType="customAndWifi"
      metaDescription="Per Pane Nostrum abbiamo realizzato infrastruttura WiFi e garantito servizi di autenticazione clienti, connessione."
      contents={[{
        date:`Committente: ConfCommercio Senigallia
        Settembre 2015
        Senigallia, Piazza Del Duca – Rocca Roveresca
        
        `,
        text: `        
        Senigallia completamente inondata dai profumi e dalle fragranze del
        pane che viene cotto e sfornato in Piazza del Duca e nel Parco della
        Rocca Roveresca, con i forni a cielo aperto che hanno caratterizzano
        la manifestazione Pane Nostrum.        
        Sono stati quattro giorni ricchi di appuntamenti tra show cooking,
        lezioni di gusto, momenti di intrattenimento, incontri, laboratori,
        cinema, area food e stand legati alla produzione agricola e
        panificatoria del territorio.`,
        image: "panenostrum.png"
        },
        {
        text: {
            partOne: `Esigenze del cliente

            • Copertura WiFi delle aree evento
            • Rendere estremamente semplice e veloce la procedura di autenticazione per gli ospiti`,
            partTwo: `Le nostre soluzioni
            
            • Realizzazione infrastruttura rete WiFi per stand espositori, area spettacolo e food
            • Fornitura connettività dedicata
            • Captive portal Connect@You per l’autenticazione del pubblico e degli espositori
            • Servizio di assistenza`
          }
        }
      ]}
    />
  )
}

export default PaneNostrum
