import React from "react"
import TechSoftLayout from "../../../components/techSoftLayout/techSoftLayout.js"

const RiccioneChristmas = () => {
  return (
    <TechSoftLayout
      title="Riccione Christmas &#10;Village 2014"
      descriptionType="customAndWifi"
      contents={[{
        text: ` L’evento inedito ed esclusivo del Villaggio è lo shopping on ice in
        Viale Ceccarini con la creazione della pista di pattinaggio più
        lunga d’Europa (180mt di lunghezza, coperta da una tendostruttura in
        cristal). Per i più piccoli è stato allestito il castello di
        cristallo in piazzetta del Faro e una tendostruttura trasparente e
        riscaldata dedicata alle attività gratuite pensate per loro. E in
        più mercatini ed attrazioni. A Riccione Christmas Village arriva il
        Babbo Natale più sorprendente e high tech d’Italia! Per l’occasione
        ACME Produzioni srl sviluppa un software dedicato per la società
        Gruppo AO – Ambra Orfei.`,
        image: "riccione-christmas-village.png"
        },
        {
        text: {
            partOne: `Esigenze del cliente

            • Copertura WiFi delle aree evento
            • Rendere estremamente semplice e veloce la procedura di
            autenticazione per il pubblico`,
            partTwo: `Le nostre soluzioni

            • Realizzazione infrastruttura rete WiFi per stand espositori, area spettacolo e food
            • Fornitura connettività dedicata
            • Captive portal Connect@You per l’autenticazione del pubblico e degli espositori
            • Servizio di assistenza`
          }
        }
      ]}
    />
  )
}

export default RiccioneChristmas
