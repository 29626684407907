import React from "react"
import TechSoftLayout from "../../../components/techSoftLayout/techSoftLayout.js"
import JovaLogo from "../../../components/jovaLogo/jovaLogo.js"
import Image from "../../../components/image/image.js"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay } from "swiper"

const JovaBeachParty = () => {
  SwiperCore.use([Autoplay])

  const FIRSTCAROUSEL = [
    {
      id: 1,
      filename: "jova-beach-party-roccella-ionica.jpg",
      alt: "Jova Beach Party a Roccella Ionica",
    },
    {
      id: 2,
      filename: "jova-beach-party-roccella-ionica-vista-frontale-palco.jpg",
      alt: "Jova Beach Party a Roccella Ionica, visuale frontale palco",
    },
    {
      id: 3,
      filename: "jova-beach-party-roccella-visuale-dietro-palco-drone",
      alt: "Jova Beach Party a Roccella Ionica, visuale da drone retro palco",
    },
    {
      id: 4,
      filename: "concerto-rocella-ionica-jova-beach-party-vista-drone-pubblico",
      alt: "Jova Beach Party a Roccella Ionica, vista pubblico da drone",
    },
    {
      id: 5,
      filename:
        "jova-beach-party-roccella-ionica-esplosione-colori-vista-drone",
      alt: "Jova Beach Party a Roccella Ionica, esplosione colori vista drone",
    },
  ]

  const SECONDCAROUSEL = [
    {
      id: 1,
      filename: "customSoft/jovanotti-registrazione.jpg",
      alt: "Jovanotti durante le registrazioni",
    },
    {
      id: 2,
      filename: "customSoft/jovanotti-registrazione2.jpg",
      alt: "Jovanotti durante le registrazioni",
    },
    {
      id: 3,
      filename: "customSoft/jova-beach-party-vista-tornelli-tramonto.jpg",
      alt: "Jova Beach Party vista tornelli a Roccella Ionica",
    },
    {
      id: 4,
      filename: "customSoft/jova-beach-party-screen-app.png",
      alt: "Jova Beach Party Screenshots APP",
    },
    {
      id: 5,
      filename: "customSoft/jova-beach-party-vista-tornelli-tramonto.jpg",
      alt: "Jova Beach Party vista tornelli al tramonto a Roccella Ionica",
    },
  ]

  return (
    <TechSoftLayout
      title="Lorenzo “Jovanotti” Cherubini &#10;Jova Beach Party Tour"
      descriptionType="customAndWifi"
      description={`Per Trident Music srl abbiamo sviluppato la APP ufficiale del tour
      Jova Beach Party.`}
    >
      <div className="section" />
      <div className="columns custom m-1">
        <div className="column is-6-tablet is-12-mobile">
          <p>
            <b>
              Un'esperienza unica per tutto il nostro Staff che ha collaborato
              intensamente con la parte creativa dello Staff di Lorenzo, per la
              realizzazione di uno strumento di comunicazione all’avanguardia,
              con i più elevati standard di velocità di consultazione per la
              parte del MEGAMIX, delle JOVA Session e dei JOVA BEACH live. Il
              primo ed unico performer che ha inventato un suo spazio e un nuovo
              format di show!
            </b>
          </p>
          <br />
          <p>
            Esigenze del cliente
            <br />
            <br />• APP dedicata al Jova Beach Party tour con diverse
            funzionalità di informazione e comunicazione per il primo tour
            italiano sulle spiagge
            <br />• Podcast realizzati da Lorenzo da caricare all’interno della
            APP
            <br />• Radio dedicata ed eventuali sessioni live del tour
            <br />• Comunicazione e vendita biglietti per ogni singola data            
            <br />• Sistema per il controllo accessi staff/addetti ai
            lavori/ospiti
          </p>
          <p>
            <br />
            <br />
            Le nostre soluzioni
            <br />
            <br />• Ideazione e realizzazione della APP con sistema operativo
            iOS e Android per smartphone e tablet con sistema operativo iOS e
            Android
            <br />• Podcast di Lorenzo disponibili nella APP
            <br />• Sistema per il controllo accessi (hardware e software) di:
            staff/addetti ai lavori con scansione QRcode
            <br />• Gestione delle session live registrate durante il tour e
            pubblicate sulla APP
            <br />• Per la data di Milano-Linate: gestione accesso terrazza VIP
            tramite tornello elettronico con scansione QRcode pass
            personalizzato
            <br />• Servizio di assistenza dedicata per tutta la durata del tour
          </p>
        </div>
        <div className="column is-6-tablet is-12-mobile">
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            grabCursor={true}
            loop={true}
            autoplay={{ delay: 2500, disableOnInteraction: false }}
          >
            {FIRSTCAROUSEL.map(i => (
              <SwiperSlide key={i.id}>
                <Image filename={i.filename} alt={i.alt} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="section" />
      <div className="columns custom m-1 invert">
        <div className="column is-6-tablet is-12-mobile">
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            grabCursor={true}
            loop={true}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
          >
            {SECONDCAROUSEL.map(i => (
              <SwiperSlide key={i.id}>
                <Image filename={i.filename} alt={i.alt} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="column is-6-tablet is-12-mobile">
          <p>
            Un po' di numeri
            <br />
            <br />• downloads totali della APP 205.521
            <br />• Android 109.328
            <br />• iOS 96.123
            <br />• utenti registrati 162.255
            <br />
            <br />
            La APP è ancora disponibile su Apple Store e Google Play,
            costantemente aggiornata secondo esigenze del cliente.
          </p>
          <div className="section" />
          <div className="columns goToApp">
            <div className="column">
              <a
                rel="noreferrer"
                href="http://onelink.to/ym9stb"
                target="_blank"
              >
                <button className="goTo">Vai all'APP</button>
              </a>
            </div>
            <div className="column">
              <a
                target="_blank"
                rel="noreferrer"
                href="http://onelink.to/ym9stb"
                style={{ maxWidth: "50%" }}
              >
                <JovaLogo />
              </a>
            </div>
          </div>
        </div>
      </div>
    </TechSoftLayout>
  )
}

export default JovaBeachParty
