import React from "react"
import './style.scss'

const List = ({ children, className = '' }) => {


  return (
    <ul className={`acme-list ${className}`}>
      {children}
    </ul>
  )
}

export default List
