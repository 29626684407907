import React from "react"
import EntertainmentLayout from "../../../components/entertainmentLayout/entertainmentLayout.js"

const DolcementeUmbria = () => {
  return (
    <EntertainmentLayout
      category="Segreteria Organizzativa"
      title="Dolcemente Umbria"
      bannerImage="banner-dolcemente.jpg"
      metaDescription="Dolcemente Umbria è la mostra mercato interamente dedicata ai dolci tipici natalizi della tradizione spoletina e umbra."
      description="Dolcemente Umbria è la mostra mercato interamente dedicata ai dolci
      tipici natalizi della tradizione spoletina e umbra."
      contents={[{
        date:`Committente Comune di Spoleto
        Dicembre 2013
        Spoleto, Piazza del Mercato
        `,
        text:`Nove gli spazi espositivi che, durante la due giorni, rappresentano
        il centro dell’iniziativa e attorno ai quali ruotano appuntamenti ed
        happening di vario genere: insieme alle esposizioni e al mercatino
        vero e proprio, è possibile partecipare alle degustazioni e ai
        laboratori, assistere al Teatro Comico e alle animazioni itineranti,
        usufruendo in tutta l’area anche del WiFi gratuito. In vista della
        manifestazione “Dolcemente Umbria”, tanti sono gli studenti
        dell’Ipssart “G. De Carolis” di Spoleto intenti sia a sfornare dolci
        tipici umbri sia a cimentarsi nell’arte del cake design, per rendere
        più dolce e piacevole la visita al mercatino allestito in piazza del
        Mercato e agli eventi sparsi per le vie del centro cittadino.
      
        Spettacoli Gianluca “Chicco d’Oliva - Mariello” Impastato, Gianluca
        “Scintilla - Romolo Prinz” Fubelli, in Dolcemente Rido. Special
        guest Alberto Farina`,
        //image: "dolcemente.png"  
      }]} 
    />
  )
}

export default DolcementeUmbria
