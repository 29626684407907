import React, { useContext } from "react"
import { Link } from "gatsby"
import {motion} from "framer-motion"
import { ThemeContext } from '../../context/ThemeContext'
//import { useNavigate } from "@reach/router"

const MenuItem = ({ label, index, to }) => {
  //const navigate = useNavigate()
  const theme = useContext(ThemeContext);

  function close() {
    theme.setCurrentMenu(false);
  }

  const animations = {
    style: {
      position: "absolute",
      y:  (index-1) * 70,
      width: "100%",
    },
    initial: { x: -100, opacity: 0 },
    animate: { x: 0, opacity: 1 },
    exit: { x: 100, opacity: 0 },
    transition: { type: "spring", stiffness: 900, damping: 40, duration: 10.3, delay: index * 0.1 }
  };

  return (
    <motion.li
        {...animations} layout
    >
      <motion.div
        //onClick={() => navigate("page2")}
      >
        <Link to={to} onClick={close}>{label}</Link>
      </motion.div>
    </motion.li>
  )
}

export default MenuItem
