import React from "react"
import EntertainmentLayout from "../../../components/entertainmentLayout/entertainmentLayout.js"

const DolceUmbria = () => {
  return (
    <EntertainmentLayout
      category="Segreteria Organizzativa"
      title="La Dolce Umbria"
      bannerImage="banner-dolce-umbria.jpg"
      metaDescription="La Dolce Umbria è una mostra mercato interamente dedicata ai dolci tipici natalizi della tradizione spoletina e umbra."
      description="La Dolce Umbria è una mostra mercato interamente dedicata ai dolci
      tipici natalizi della tradizione spoletina e umbra."
      contents={[{
        date:`Committente Comune di Spoleto
        Ottobre 2014
        Spoleto, Complesso Monumentale Chiostro di San Nicolò
        `,
        text:`Numerosi gli spazi espositivi che, durante la due giorni,
        rappresenteranno il centro dell’iniziativa e attorno ai quali
        ruoteranno appuntamenti ed happening di vario genere: insieme alle
        esposizioni e al mercatino vero e proprio, sarà possibile
        partecipare alle degustazioni e ai laboratori di cucina per scoprire
        le origini dei tipici dolci spoletini. Durante la giornata anche
        intrattenimento con live music per allietare i presenti.
        
        Performance artistica Live Music, Gangster in Love, Video Killer 80,
        7 cervelli`,
        //image: "dolceumbria.png"  
      }]} 
    />
  )
}

export default DolceUmbria
