import React from "react"
import TechSoftLayout from "../../../components/techSoftLayout/techSoftLayout.js"

const Baccaresca = () => {
  return (
    <TechSoftLayout
      title="Castello di &#10;Baccaresca"
      descriptionType="customAndWifi"
      metaDescription="Per il Castello di Baccaresca abbiamo migliorato l’infrastruttura WiFi e garantito servizi di autenticazione clienti, connessione, e sistema di centralino VoIP."
      contents={[{
        date:`Committente: Società Agricola FEL | Castello di Baccaresca
        Anno: 2016-2020
        
        `,
        text: `       
        Il Castello di Baccaresca è una tenuta a pochi chilometri da Gubbio, la cittadina medievale nel cuore dell’Umbria. 
        La sua storia inizia nel XII secolo.
        La sua posizione lo rendeva un luogo di particolare importanza strategica e militare — molti sono stati i signori 
        e i nobili che si sono succeduti — ed ognuno di essi ha voluto lasciare una traccia del suo passaggio.
        La tenuta del Castello di Baccaresca si compone di circa 250 ettari, tra boschi secolari e campi coltivati attraversati 
        da sentieri e antiche mulattiere.
        Un luogo unico e fiabesco, di quelli che solo la verde Umbria sa regalare.
        Nel corso dei secoli, il Castello di Baccaresca si è ingentilito e l’antica funzione militare ha lasciato il posto ad 
        un castello nobiliare abbellito con affreschi e camini monumentali che oggi sono a disposizione degli ospiti, 
        grazie ad una imponente opera di restauro.`,
        image: "baccaresca.png"
        },
        {
        text: {
            partOne: `Esigenze del cliente

              • Migliorare le performance della WiFi all’interno e all’esterno della struttura ricettiva
              • Risolvere problematiche di collegamento verso l’esterno
              • Avere uno strumento di sicurezza per le connessioni ad internet
              • Rendere estremamente semplice e veloce la procedura di autenticazione per i clienti
              • Aggiornamento del servizio centralino e dei collegamenti interni`,
            partTwo: `Le nostre soluzioni
            
              • Fornitura armadio rack, ups e apparati per distribuzione servizi WiFi all’interno e all’esterno della struttura
              • Captive portal Connect@You per autenticazione dei clienti della struttura
              • Sistema di centralino VoIP
              • Fornitura servizio VoIP e apparati telefonici
              • Servizio di assistenza`
          }
        }
      ]}
    />
  )
}

export default Baccaresca
