import React from "react"
import TechSoftLayout from "../../../components/techSoftLayout/techSoftLayout.js"

const BagnoTiki26 = () => {
  return (
    <TechSoftLayout
      title="Bagno Tiki 26"
      descriptionType="customAndWifi"
      metaDescription="Per Bagno Tiki 26 abbiamo realizzato infrastruttura WiFi e garantito servizi di autenticazione clienti, connessione, software di brand reputation e smart menù."
      contents={[{
        date:`Committente: A Mareina srl | Bagno Tiki 26 Rimini
        Anno: 2020
        
        `,
        text: ` 
        Il Bagno Tiki 26 nasce nell’estate 1987 da un’idea dei cugini
        Pagliarani, Fabrizio e Gabriele, e dall’aiuto dei loro genitori.
        Dalla 2° stagione sono iniziati una serie di interventi per
        migliorare le comodità dei clienti; l’idea è di istituire uno
        stabilimento che vada oltre i servizi tradizionali di noleggio
        lettini e ombrelloni, con proposte diverse e mirate ad ogni
        clientela. La qualità del Bagno 26 consiste nel rispetto delle
        regole che garantiscono alla clientela professionalità e serietà,
        offrendo le migliori condizioni della spiaggia con la massima tutela
        dell’ambiente.`,
        image: "tiki26.png"
        },
        {
        text: {
            partOne: `Esigenze del cliente 

            • Realizzare una infrastruttura WiFi performante nell’area degli ombrelloni e del ristorante
            • Avere uno strumento di sicurezza per le connessioni ad internet
            • Rendere estremamente semplice e veloce la procedura di autenticazione per i clienti
            • Poter controllare e gestire le recensioni online
            • Avere uno strumento per gli ordini dagli ombrelloni e dal ristorante`,
            partTwo: `Le nostre soluzioni 
            
            • Realizzazione di una infrastruttura WiFi nella zona degli ombrelloni fino al bagnasciuga e nel ristorante
            • Captive portal Connect@You per autenticazione dei clienti dello stabilimento
            • Fornitura connessione dedicata stagionale
            • Software Eucleia per la reputazione online e Fast Review
            • Software Paiazo | Smart Menu per la gestione degli ordini dagli ombrelloni e per il ristorante
            • Servizio di assistenza`
          }
        }
      ]}
    />
  )
}

export default BagnoTiki26
