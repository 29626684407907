import React from "react"
import TechSoftLayout from "../../../components/techSoftLayout/techSoftLayout.js"

const DomusPacis = () => {
  return (
    <TechSoftLayout
      title="Domus Pacis &#10;Assisi "
      descriptionType="customAndWifi"
      metaDescription="Domus Pacis: migliorato performance WiFi e garantito servizio autenticazione clienti, connessione, software booking engine e channel manager, brand reputation."
      contents={[{
        date:`Committente: Provincia Serafica OFM | Domus Pacis
        Anno: 2015-2020
        
        `,
        text: `  
        La Domus Pacis opera in Assisi, per privati, gruppi e convegni, con
        lo spirito che da sempre caratterizza i francescani: l’accoglienza.
        Da otto secoli, infatti, pellegrini e uomini in ricerca di spiritualità 
        trovano in questi luoghi una fonte di speranza e di crescita.`,
        image: "domuspacis.png"
        },
        {
        text: {
            partOne: `Esigenze del cliente

            • Migliorare le performance della WiFi all’interno della struttura ricettiva
            • Avere uno strumento di sicurezza per le connessioni ad internet
            • Rendere estremamente semplice e veloce la procedura di autenticazione per i clienti
            • Fornitura dei servizi di prenotazione online
            • Fornitura di un servizio per la gestione ed il controllo della brand reputation`,
            partTwo: `Le nostre soluzioni

            • Fornitura armadio rack, bilanciatore connessione fibra dedicata, realizzazione dorsale in fibra monomodale
            • Captive portal Connect@You per autenticazione dei clienti della struttura
            • Software Book@ME booking engine + channel manager
            • Software Eucleia brand reputation e controllo recensioni online
            • Realizzazione sito internet struttura ricettiva e parte convegnistica
            • Servizio di assistenza`
          }
        }
      ]}
    />
  )
}

export default DomusPacis
