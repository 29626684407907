import React, { useContext, useEffect } from "react"
import { Helmet } from "react-helmet"
import { ThemeContext } from "../context/ThemeContext"
//import Newsletter from "../components/newsletter/newsletter"

import '../assets/styles/pages/_contacts.scss'

const Contacts = () => {
  const theme = useContext(ThemeContext)

  useEffect(() => {
    theme.setBackGroundColor("white")
    theme.setVisibleFooter(false)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contatti | ACME Produzioni</title>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="contact">
        <div className="columns">
          <div className="column">
            <h1>Contatti</h1>
            <h2>Sede Legale Circonvallazione Clodia, 163/171 00195 Roma</h2>
            <h2>+39 06 21128365</h2>
          </div>
        </div>
      </div>
      {/* <div className="newsletter">
        <div className="container">
          <div className="columns is-mobile">
            <div className="column is-half">
              <div className="subscribe">
                <b>Iscriviti alla Newsletter</b>
                <br />
                <p>Rimani in contatto con il mondo Acme.</p>
                <br />
                <Newsletter>
                  <br />
                  <button type="submit">Iscriviti</button>
                </Newsletter>
                <div id="emailMobile">
                  <a href="mailto:info@acmeproduzioni.it">
                    <b>To: info@acmeproduzioni.it</b>
                  </a>
                </div>
              </div>
            </div>
            <div className="column is-half">
              <div className="email">
                <span>@</span>
                <a href="mailto:info@acmeproduzioni.it">
                  <b>To: info@acmeproduzioni.it</b>
                </a>
                <br />
                <p>Object Information</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  )
}

export default Contacts
