import React from "react"
import EntertainmentLayout from "../../../components/entertainmentLayout/entertainmentLayout.js"

const AnnoMenottiano = () => {
  return (
    <EntertainmentLayout
      category="Segreteria Organizzativa"
      title="Anno Menottiano&#10; Centenario &#10;Maestro Gian Carlo Menotti"
      bannerImage="banner-annomenottiano"
      metaDescription="ACME Produzioni, il Maestro Steven Mercurio e l’Accademia Nazionale di Santa Cecilia hanno celebrato in occasione del Festival dei 2Mondi il Maestro Menotti."
      description=" Grazie al rapporto di collaborazione consolidato con il Maestro
      Steven Mercurio, la ACME Produzioni ha coinvolto il Maestro nella
      stesura di un progetto artistico per la realizzazione di un evento
      unico per la celebrazione del centesimo anniversario dalla nascita
      del Maestro Gian Carlo Menotti, talent scout per musica, teatro e
      danza conosciuto in tutto il mondo che, nel 1958 ha fondato il
      Festival dei Due Mondi di Spoleto."
      contents={[{
        date:`Committente: Associazione “Celebrazioni Anno Menottiano”
        Spoleto, Piazza Duomo
        Luglio 2011
        `,
        text:`In collaborazione con gli organizzatori, ACME ha svolto l'attività
        di segreteria organizzativa e ha presentato un progetto artistico ad
        Umberto Nicoletti Altimari, per coinvolgere l'Accademia Nazionale di
        Santa Cecilia sotto la sovraintendenza di Bruno Cagli, il quale
        entusiasta del progetto, propone per la direzione di Mercurio la
        JuniOrchestra, composta da circa 260 strumentisti con età compresa
        tra i 14 e i 21 anni.
                 
        Il Maestro dirige il Concerto per Gian Carlo, arricchito dalle
        melodie della JuniOrchestra, con ospite d’eccezione il Violoncello
        solista Michael Fitzpatrick.
                 
        “Sono molto felice di tornare a Spoleto e ho ottimi ricordi della
        città, che sarà per sempre casa mia. Con Menotti ho collaborato nel
        1997 in occasione del Festival dei Due Mondi e non vedo l’ora di
        tornare. Non esiste alcun luogo in cui desideri tornare di più il 7
        luglio”. Steven Mercurio
                  
        L’iniziativa è soltanto una delle tantissime contenute all’interno
        del cartellone. Un vero e proprio trionfo di musica, teatro,
        balletti e convegni, reso possibile grazie alla fruttuosa e intensa
        collaborazione tra il Comune di Spoleto, la Fondazione Festival dei
        Due Mondi e l’Associazione “Celebrazioni Anno Menottiano”.
        `,
        //image: "annomenottiano.jpg"  
      }]}
      guests={
        <>
          <b>Sul palco:</b>
          <br />
          Direttore d’Orchestra Steven Mercurio,
          <br />
          Violoncello solista Michael Fitzpatrick,
          <br />
          JuniOrchestra dell’Accademia Nazionale di Santa Cecilia
        </>
      }
    />
  )
}

export default AnnoMenottiano
