import React, { useContext, useEffect } from "react"
import Header from "../../components/header/header.js"
import Description from "../../components/description/description.js"
import { ThemeContext } from "../../context/ThemeContext"
import DuotoneImage from "../../components/duotoneImage/duotoneImage.js"
import Card from "../../components/card/Card.js"

import '../../assets/styles/pages/_entertainment.scss'

const CARDS = [
  {id: '1', to: '/entertainment/produzione/alice', filename:'alice', title:'Alice nel Paese delle Meraviglie', subtitle: 'Il Musical'},
  {id: '2', to: '/entertainment/portfolio/annomenottiano', filename: 'annomenottiano', title: 'Anno Menottiano'},
  {id: '3', to: '/entertainment/produzione/venditti', filename:'venditti', title:'Antonello Venditti', subtitle: 'Special Event Live'},  
  {id: '4', to: '/entertainment/produzione/cochierenato', filename:'cochirenato', title:'Cochi e Renato', subtitle: 'Finchè c\'è la salute'},
  {id: '5', to: '/entertainment/portfolio/dolceumbria', filename: 'dolceumbria', title: 'La Dolce Umbria'},
  {id: '6', to: '/entertainment/portfolio/dolcementeumbria', filename: 'dolcemente', title: 'Dolcemente Umbria'},
  {id: '7', to: '/entertainment/produzione/brignano', filename:'brignano', title:'Enrico Brignano', subtitle: 'Evolushow'},
  {id: '8', to: '/entertainment/produzione/gabbani', filename:'gabbani', title:'Francesco Gabbani', subtitle: 'Tour Magellano'},
  {id: '9', to: '/entertainment/produzione/allevi', filename:'allevi', title:'Giovanni Allevi', subtitle: 'Alien World Tour'},
  {id: '10', to: '/entertainment/produzione/ilovespoleto', filename:'ilovespoleto', title:'I Love Spoleto 2018'},
  {id: '11', to: '/entertainment/portfolio/lifeingubbio', filename: 'lifeingubbio', title: 'Life in Gubbio'},
  {id: '12', to: '/entertainment/produzione/lifeinumbria', filename:'lifeinumbria', title:'  Life in Umbria...', subtitle: 'lo spettacolo prende vita'},
  {id: '13', to: '/entertainment/produzione/mannarino', filename:'mannarino', title:'Mannarino', subtitle: 'Tour Al Monte Live'},
  {id: '14', to: '/entertainment/portfolio/spoletotipica', filename: 'spoletotipica', title: 'Spoleto Tipica'},
  {id: '15', to: '/entertainment/produzione/teatro-comico', filename:'teatrocomico', title:'Teatro Comico'},
  {id: '16', to: '/entertainment/produzione/mammucari', filename:'mammucari', title:'Teo Mammucari', subtitle:'Live'},
  {id: '17', to: '/entertainment/produzione/trepianogen', filename:'3pianogen', title:'Luis Bacalov, Rita Marcotulli e Alberto Pizzo', subtitle:'3 Piano Generations'},  
     
]

const Segreteria = () => {
  const theme = useContext(ThemeContext)
  
  useEffect(() => {
    theme.setBackGroundColor("red")
    theme.setOriginPage({
      label: "Segreteria Organizzativa",
      to: "/entertainment/segreteria",
    })
    theme.setVisibleFooter(true)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header
        title="Segreteria Organizzativa"
        bannerImage="entertainment/segreteria-organizzativa-banner"
        metaDescription="ACME è in grado di dare agli eventi un valore aggiunto: l’attenzione meticolosa ai dettagli, rendendo ogni appuntamento un’esperienza unica!"
      />
      <Description type="onTheLeft">
        <h2>
          ACME è in grado di dare agli eventi un valore aggiunto: l’attenzione
          meticolosa ai dettagli, rendendo ogni appuntamento un’esperienza
          unica!
        </h2>
        <p>
          Per soddisfare le esigenze dei clienti mette in campo
          la professionalità del suo team, in grado di seguire le fasi
          organizzative ed operative; ideazione, sopralluoghi, logistica,
          coordinamento delle risorse, disbrigo pratiche amministrative,
          gestione pratiche di Pubblico Spettacolo, S.I.A.E, rilascio licenze
          temporanee.
        </p>
      </Description>
      <div className="entertainment">
        <div className="columns">
          {CARDS.map(i => (
            <Card
              key={i.id}
              {...i}
              className="column is-one-third-desktop is-half-tablet is-full-mobile"
              imageComponent={DuotoneImage}
              imageType="negative"
            />
          ))}
        </div>
      </div>
    </>
  )
}

export default Segreteria
