import { Link } from "gatsby"
import React, { useContext } from "react"
import Menu from "../menu/menu.js"
import "./style.scss"
import { ThemeContext } from '../../context/ThemeContext'
import MenuMobile from '../menuMobile/menuMobile.js'


const Navbar = () => {
  const theme = useContext(ThemeContext);

  const MENUS = [
    {
      id: 'technology',
      label:'Tech & Software',
      to: '/tech-soft/',
      children: [
        {id: 'saas', label:'I nostri prodotti', to: '/tech-soft/saas'},
        {id: 'customsoftware', label:'APP & Software Personalizzati', to: '/tech-soft/custom-software'},
        {id: 'itservice', label:'Infrastrutture reti', to: '/tech-soft/it-service'},
        {id: 'websitesCreation', label:'Realizzazione Siti web', to: '/tech-soft/websites-creation'}
      ]
    },
    {
      id: 'entertainment',
      label:'Entertainment',
      to: '/entertainment/',
      children: [
        {id: 'produzione', label:'Produzione', to: '/entertainment/produzione'},
        {id: 'artistica', label:'Direzione Artistica', to: '/entertainment/artistica'},
        {id: 'tecnica', label:'Direzione Tecnica', to: '/entertainment/tecnica'},
        {id: 'segreteria', label:'Segreteria Organizzativa', to: '/entertainment/segreteria'},
        {id: 'allestimenti', label:'Allestimenti', to: '/entertainment/allestimenti'},
      ]
    },
    {
      id: 'cinemaspottv',
      label:'Cinema, Spot & Tv',
      to: '/cinemaspottv',
      children: null
    },
    {
      id: 'lavori',
      label:'Lavori',
      to: '/ourworks',
      children: null
    },
    {
      id: 'news',
      label:'Blog',
      to: '/blog',
      children: null
    },
    {
      id: 'aboutUs',
      label:'Chi Siamo',
      to: '/about-us',
      children: null
    },
    {
      id: 'contacts',
      label:'Contatti',
      to: '/contacts',
      children: null
    }
  ]


  function open(menu) {
    theme.setCurrentMenu(menu);
  }

  function close() {
    theme.setCurrentMenu(false);
  }

  return (
    <header>
      <div className={`backGround ${theme.backGroundColor}`}>
      <div className="container is-fullhd">
        <div className="inner-header">
          <div className="logo">
            <Link to="/">
              <img alt="ACME Produzioni Logo - Vai alla Home" width="150px" height="56px" id="logo" src={theme.logo}/>
            </Link>
          </div>
          <div className="navigation">
            <nav>
              {
                MENUS.map(menu => <Link onClick={close} onMouseEnter={() => open(menu)} key={menu.id} to={menu.to}>{menu.label}</Link>)
              }
            </nav>
          </div>
          <div className="social">
            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/acmeproduzioni/">Fb</a>
            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/acmeproduzioni_official/">Ig</a>
            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/acme-produzioni/">In</a>
          </div>
        </div>
      </div>
      <Menu menu={theme.currentMenu} />
      <MenuMobile toggle={theme.toggleMobileMenu} MENUS={MENUS}/>
      </div>
    </header>
  )
}

export default Navbar
