import React from "react"
import TechSoftLayout from "../../../components/techSoftLayout/techSoftLayout.js"

const LeBefane = () => {
  return (
    <TechSoftLayout
      title="Le Befane &#10;Shopping Centre"
      descriptionType="customAndWifi"
      metaDescription="Per Befane Shopping Centre abbiamo migliorato le performance della WiFi e garantito servizi di autenticazione clienti, connessione, sistema di centralino, etc."
      contents={[{
        date:`Committente: Consorzio Centro Commerciale Le Befane
        Anno: 2020-2025
        
        `,
        text: `
        Le Befane Shopping Centre, il grande centro commerciale di Rimini,
        tra i più grandi d’Europa, raccoglie al suo interno circa 150
        negozi. Si tratta di un luogo in grado di offrire ristoro per ben
        363 giorni all’anno, un luogo dove potersi riscaldare in inverno e
        rinfrescare in estate, dando pieno spazio allo svago e
        allo shopping. Potrete soffermarvi e fare una sosta in uno dei bar.
        Troverete tanto svago nella sala giochi e non manca il mini club per
        i bimbi. Per gli appassionati di cinema c’è il multisala Multiplex,
        di ben 12 sale e potrete assistere a prime visioni internazionali e
        guardare i film d’autore.`,
        image: "lebefane.png"
        },
        {
        text: {
            partOne: `Esigenze del cliente

            • Migliorare le performance della WiFi all’interno del centro commerciale
            • Risolvere problematiche di collegamento verso l’esterno degli uffici della direzione, dell’infopoint e della vigilanza
            • Avere uno strumento di sicurezza per le connessioni ad internet
            • Rendere estremamente semplice e veloce la procedura di autenticazione per i clienti
            • Aggiornamento del servizio centralino e dei collegamenti interni`,
            partTwo: `Le nostre soluzioni

            • Fornitura connessione in fibra dedicata simmetrica
            • Captive portal Connect@You per autenticazione dei clienti del centro
            • Fornitura firewall
            • Sistema di centralino VoIP
            • Fornitura servizio VoIP e apparati telefonici
            • Servizio di assistenza`
          }
        }
      ]}      
    />
  )
}

export default LeBefane
