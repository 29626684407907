import React from "react"
import EntertainmentLayout from "../../../components/entertainmentLayout/entertainmentLayout.js"

const Mammucari = () => {
  return (
    <EntertainmentLayout
      category="Segreteria Organizzativa"
      title="Teo Mammucari Live"
      bannerImage="mammucari.jpg"
      metaDescription="Durante lo show il celebre comico Teo Mammucari, ha tirato fuori la verità e i pensieri personali che ha portato con sé per tutti questi anni."
      description="Non sono bastati più di 20 programmi televisivi a tirare fuori la
      verità e i pensieri personali che Teo Mammucari ha portato con sé
      per tutti questi anni."
      contents={[{
        date:`Novembre 2018 
        Fabriano, Teatro Gentile
        
        Dicembre 2018
        Foligno, Auditorium San Domenico
        `,
        text:`Ha scelto il teatro e sposato il progetto di presentare un monologo
        dove può finalmente parlare con il suo cinismo di quello che è
        accaduto al suo mondo. Uno spettacolo comico, molto irriverente
        attraverso cui il pubblico viene coinvolto in tutto quello che Teo
        ha vissuto in questi 20 anni, della sua vita personale. Questo
        spettacolo è il suo punto di partenza per questo lungo viaggio che
        si chiama vita, uno show inedito che attraversa l’amore, la gioia di
        essere diventato padre, ma soprattutto la speranza di uscire da
        quello che il programma sociale ci impone. Scritto e diretto da Teo
        Mammucari che ha raccolto in chiave ironica e comica una realtà che
        ci ha trasformato in robot.`,
        //image: "mammucari.jpg"  
      }]}
    />
  )
}

export default Mammucari
