import React from "react"
import TechSoftLayout from "../../../components/techSoftLayout/techSoftLayout.js"

const Eurochocolate = () => {
  return (
    <TechSoftLayout
      title="Eurochocolate"
      descriptionType="customAndWifi"
      metaDescription="Per Eurochocolate ACME ha realizzato l’infrastruttura WiFi e garantito servizi di autenticazione clienti, connessione."
      contents={[{
        date:`Committente: Sedicieventi - Div. Gioform Srl
        Anno: Ottobre 2014, Ottobre 2016
        Luogo: Perugia
        
        `,
        text: `
        Eurochocolate è il Grande Festival Internazionale del Cioccolato
        che, fra attese conferme e golose novità, si svolge a Perugia. Tra
        le principali attrazioni la grande installazione esposta in Piazza
        IV Novembre. L’originale mostra per ripercorrere la storia e il
        costume con simpatici contest. Oltre a questo la festa del
        cioccolato che ogni anno si tiene a Perugia offre un ricchissimo
        programma e un fitto calendario di eventi.`,
        image: "chocostore.png"
        },
        {
        text: {
            partOne: `Esigenze del cliente

            • Copertura WiFi dell’area evento e della sala stampa.
            • Rendere estremamente semplice e veloce la procedura di autenticazione per gli ospiti`,
            partTwo: `Le nostre soluzioni

            • Realizzazione infrastruttura rete WiFi per stand espositori, area spettacolo e sala stampa
            • Fornitura connettività dedicata
            • Captive portal Connect@You per l’autenticazione del pubblico e degli espositori
            • Servizio di assistenza`
          }
        }
      ]}
    />
  )
}

export default Eurochocolate
