import React, { useContext, useEffect } from "react"
import Header from "../../components/header/header.js"
import Description from "../../components/description/description.js"
import { ThemeContext } from "../../context/ThemeContext"
import Image from "../../components/image/image.js"
import {List, ListItem} from "../../components/list"

import "./style.scss"
import BackTo from "../backTo/BackTo.js"

const EntertainmentLayout = props => {
  const theme = useContext(ThemeContext)

  const SERVICES = [
    { category: "Produzione", to: "/entertainment/produzione" },
    { category: "Direzione Artistica", to: "/entertainment/artistica" },
    { category: "Direzione Tecnica", to: "/entertainment/tecnica" },
    { category: "Segreteria Organizzativa", to: "/entertainment/segreteria" },
    { category: "Allestimenti", to: "/entertainment/allestimenti" },
  ]

  useEffect(() => {
    theme.setBackGroundColor("red")
    theme.setVisibleFooter(true)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header title={props.title} bannerImage={props.bannerImage} />
      <Description type="onTheLeft">
        <h2>{props.description}</h2>
      </Description>

      <div className="entertainment-contents">
        {(props.contents || []).map((i, index) => (
          <div
            className="entertainment-content columns p-2"
            key={Math.random()}
          >
            {index % 2 === 0 && (
              <SideImage image={i.image} alt={props.title} left={true} />
            )}

            <div
              className={`entertainment-content__text column is-one-third-desktop is-half-tablet is-full-desktop`}
            >
              <b>{i.date}</b>
              <br />
              {i.text}
            </div>

            {index % 2 !== 0 && <SideImage image={i.image} alt={props.title} />}
          </div>
        ))}
      </div>

      {props.children}

      {props.guests && (
        <div className="guests">
          <div className="container">{props.guests}</div>
          {props.firstAuthor && (
              <div className="container">
                <div className="columns">
                  <div className="column">
                    <div className="authors">{props.firstAuthor}</div>
                  </div>
                  {props.secondAuthor && (
                    <div className="column">
                      <div className="authors">{props.secondAuthor}</div>
                    </div>
                  )}
                </div>
              </div>
            )}
        </div>
      )}

      <div className="otherServices">
        <h2>Altri servizi</h2>
      </div>
      <List>
        {SERVICES.filter(i => i.category !== theme.originPage?.label).map(
          service => (
            <ListItem to={service.to} key={service.category}>
              {service.category}
            </ListItem>
          )
        )}
      </List>
      <BackTo
        to={theme.originPage?.to}
        label={theme.originPage?.label}
        classColor={"red"}
        stopColor={"#000000"}
      />
    </>
  )
}

export default EntertainmentLayout

const SideImage = ({image, alt, left = false}) => (
  <div className={`imgDescription column is-one-third-desktop is-half-tablet is-full-desktop
        ${left ? 'is-offset-one-third-desktop' : ''}
  `}>
    <Image 
      className={`column is-two-thirds-tablet ${left ? 'is-offset-one-third-tablet' : ''}`}
      filename={image}
      alt={alt}/>
  </div>
 
)