import React from "react"
import EntertainmentLayout from "../../../components/entertainmentLayout/entertainmentLayout.js"

const Brignano = () => {
  return (
    <EntertainmentLayout
      category="Segreteria Organizzativa"
      title="Enrico Brignano &#10;Evolushow"
      bannerImage="brignano-banner.jpg"
      metaDescription="Spoleto torna ad ospitare nella suggestiva cornice di Piazza Duomo uno dei più grandi artisti italiani, Enrico Brigano. "
      description=" Spoleto torna ad ospitare nella suggestiva cornice di Piazza Duomo
      uno dei più grandi artisti italiani, Enrico Brigano. ACME in accordo
      con il produttore del tour fa in modo che le esigenze dell’artista
      possano convivere al meglio con la location stessa, facendola
      diventare parte integrante della scenografia dello spettacolo."
      contents={[{
        date:`Agosto 2014
        Spoleto, Piazza Duomo
        
        Novembre 2014
        Fabriano, Teatro Gentile
        `,
        text:`Dopo la brillante e riuscitissima tournée estiva, che lo ha visto esibirsi
        sui palchi delle piazze d’Italia, Enrico Brignano sceglie di andare
        in scena anche nei teatri (partendo da Bologna, passando per i
        teatri italiani più importanti; Milano, Genova, Brescia, Napoli,
        Firenze, Torino, etc., e naturalmente Roma con il Sistina).
        Grazie al rapporto di collaborazione consolidato con Massimiliano
        Franco, produttore e manager storico di Enrico Brignano, si decide
        di studiare insieme l’organizzazione delle prove dello spettacolo
        per la tournée invernale.
        
        ACME ha supportato ogni esigenza della produzione e dell’artista,
        occupandosi della ricerca della location, della gestione hospitality
        per l’intera troupe, e della segreteria.
        Per il debutto e le prove tecniche viene scelto il Teatro
        Gentile di Fabriano, uno dei più importanti e rinomati teatri
        italiani, che negli anni ha accolto le più famose produzioni
        teatrali.

        Enrico Brignano intrattiene il pubblico con uno show tutto
        nuovo nel quale ripercorre i nostri tempi, dedicato all’evoluzione
        dei comportamenti e al nostro rapporto ormai vitale con la
        tecnologia.

        “Un'evoluzione è un destino”, diceva Thomas Mann.
        “Le specie non sono immutabili; ma sono la conseguenza di
        qualche altra specie generalmente estinta”, diceva Darwin.
        Allora, se il destino dell’uomo deve cambiare, è mutante…
        Queste sono le prime riflessioni che hanno portato Brignano a
        decidere di fare uno spettacolo nuovo, sull’evoluzione umana. In due
        ore, racconta millenni di storia, dal paradiso terrestre al digitale
        terrestre, dalla mela di Adamo alla mela di Jobs, dal fossile al
        missile.
        
        Ma se nulla si crea e nulla si distrugge, perché al cambio di
        stagione ci rimane un sacco di roba fuori dall’armadio?`,
         //image: "brignano.jpg"       
        }]}  
    />
  )
}

export default Brignano
