import React from "react"
import TechSoftLayout from "../../../components/techSoftLayout/techSoftLayout.js"

const MaltoLivello = () => {
  return (
    <TechSoftLayout
      title="Malto Livello &#10;Il festival della birra di qualità"
      descriptionType="customAndWifi"
      metaDescription="Per Malto Livello abbiamo realizzato infrastruttura WiFi e garantito servizi di autenticazione clienti, connessione."
      contents={[{
        date:`Committente: Sedicieventi - Div. Gioform Srl
        Giugno 2015
        Perugia, Area del Percorso Verde Pian di Massiano
        
        `,
        text: `
        Un vero e proprio viaggio nel mondo delle birre, quello proposto a
        Perugia da Malto Livello, il primo Festival nazionale della Birra di
        qualità. L’evento, per appassionati e intenditori, ha offerto il
        meglio delle birre: bionde, rosse e scure, tutte rigorosamente di
        qualità. Un calendario ricco di eventi con convegni, degustazioni
        guidate, laboratori, attività ludiche e uno spazio speciale
        riservato alla tendenza gastronomica del momento: lo street food. Ad
        accompagnare le birre, infatti, ci sarà il cibo di strada nelle sue
        varianti regionali.`,
        image: "malto.png"
        },
        {
        text: {
            partOne: `Esigenze del cliente

            • Copertura WiFi delle aree evento
            • Rendere estremamente semplice e veloce la procedura di autenticazione per gli ospiti`,
            partTwo: `Le nostre soluzioni
            
            • Realizzazione infrastruttura rete WiFi per stand espositori, area spettacolo e food
            • Fornitura connettività dedicata
            • Captive portal Connect@You per l’autenticazione del pubblico e degli espositori
            • Servizio di assistenza`
          }
        }
      ]}      
    />
  )
}

export default MaltoLivello
