import React from "react"
import EntertainmentLayout from "../../../components/entertainmentLayout/entertainmentLayout.js"

const Alice = () => {
  return (
    <EntertainmentLayout
      category="Segreteria Organizzativa"
      title="Alice nel Paese &#10;delle Meraviglie &#10;Il Musical"
      bannerImage="aliceBanner.jpg"
      metaDescription="Il musical Alice nel Paese delle Meraviglie porta in scena la favola nel suo aspetto più onirico e fiabesco: quello dell’immaginario dei bambini."
      description="Il musical Alice nel Paese delle Meraviglie porta in scena la favola
      nel suo aspetto più onirico e fiabesco: quello dell’immaginario dei
      bambini."
      contents={[{
        date:`Committente: Comune di Spoleto
        Dicembre 2010
        Spoleto, Teatro Nuovo “Gian Carlo Menotti”
        `,
        text:`Al pubblico viene offerta la possibilità di ritrovare la poesia del
            mondo infantile e nel contempo di confrontarsi con i significati
            simbolici e reconditi della fiaba. Il tour della piccola Alice e del
            Paese delle meraviglie va in scena in tutta Italia con le musiche di
            Daniele Biagini, i testi di Lorenzo Biagini, la sceneggiatura e
            progetto artistico di Annalisa Benedetti. Una sceneggiatura
            impreziosita dalla genialità del regista Christian Ginepro, frutto
            della rielaborazione dei famosi scritti di Lewis Carrol ˝Le
            avventure di Alice nel Paese delle Meraviglie˝ e ˝Attraverso lo
            specchio e quel che Alice vi trovò˝.`,
        //image: "aliceDes.jpg"  
      }]}   
    />
  )
}

export default Alice
