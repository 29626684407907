import React from "react"
import EntertainmentLayout from "../../../components/entertainmentLayout/entertainmentLayout.js"

const SpoletoTipica = () => {
  return (
    <EntertainmentLayout
      category="Segreteria Organizzativa"
      title="Spoleto Tipica"
      bannerImage="spoletotipica.jpg"
      metaDescription="Spoleto Tipica è la mostra mercato interamente dedicata alla promozione dei prodotti enogastronomici del territorio e della tradizione spoletina e umbra."
      description="Spoleto Tipica è la mostra mercato interamente dedicata alla
      promozione dei prodotti enogastronomici del territorio e della
      tradizione spoletina e umbra."
      contents={[{
        date:`Committente Comune di Spoleto
        2010 – 2011 – 2014        
        Spoleto, Centro Storico
        `,
        text:`Numerosi gli spazi espositivi che, durante la due giorni,
        rappresenteranno il centro dell’iniziativa e attorno ai quali
        ruotano appuntamenti di vario genere: insieme alle esposizioni e al
        mercatino vero e proprio che si disloca per il centro storico di
        Spoleto, è possibile partecipare alle degustazioni, ai laboratori di
        show cooking, e della cucina della tradizione spoletina.
         
        Il tutto accompagnato da appuntamenti musicali che si svolgono nelle
        principali vie e piazze della città.
         
         
        Ospiti
         
        Alessio Bertallot; Gianluca Petrella djset; Guidi, Calcagnile,
        Petrella Trio; FantomatiK Orchestra; Large Street Band; Gabriele
        Boggio Feraris, Mirko Mignone, Alex Carreri, Alessandro Rossi;
        Gabriele Boggio Feraris 4et;`,
        //image: "spoletotipica.jpg"  
      }]} 
    />
  )
}

export default SpoletoTipica
