import React from "react"
import TechSoftLayout from "../../../components/techSoftLayout/techSoftLayout.js"

const Corona = () => {
  return (
    <TechSoftLayout
      title="Corona SunSets Festival "
      descriptionType="customAndWifi"
      metaDescription="In occasione del Corona SunSets Festival abbiamo realizzato l’infrastruttura WiFi per stand e area spettacolo, garantendo anche un’ottima connettività."
      contents={[{
        date:`Committente: Antar Srl - Vizeum Italia S.p.A.
        Luglio | Agosto 2016, 
        Rimini
        Spiaggia libera, Piazzale Boscovich 
        Parco Federico Fellini
        
        `,
        text: `
        Il Corona SunSets Festival è un festival di musica dance ed
        elettronica pop creato per regalare un'esperienza unica. Ha permesso
        alle persone di tutto il mondo a staccare la spina celebrando
        l'energia e lo spirito del tramonto!
        Per il secondo anno consecutivo il Corona SunSets Festival è
        approdato a Rimini per l’unica tappa nel Mediterraneo di questo
        evento mondiale itinerante che nel 2016 ha scelto come location le
        più belle spiagge di Messico, Cile, Inghilterra, Brasile, Dubai,
        India. Due palchi con dj di fama mondiale della scena elettronica
        pop e dance, cibo firmato dallo chef mondiale Andre Amaro, giochi,
        animazione, una parata di lanterne come inno al tramonto e
        suggestive coreografie! Ovviamente, non mancano le aree ristoro,
        street food e stand dove acquistare prodotti e bevande di ogni tipo.`,
        image: "corona.png"
        },
        {
        text: {
            partOne: `Esigenze del cliente

            • Copertura WiFi delle aree evento
            • Rendere estremamente semplice e veloce la procedura di autenticazione per il pubblico`,
            partTwo: `Le nostre soluzioni

            • Realizzazione infrastruttura rete WiFi per stand espositori, area spettacolo e food
            • Fornitura connettività dedicata
            • Captive portal Connect@You per l’autenticazione del pubblico e degli espositori
            • Servizio di assistenza`
          }
        }
      ]}
    />
  )
}

export default Corona
