import React from "react"
import EntertainmentLayout from "../../../components/entertainmentLayout/entertainmentLayout.js"

const Mannarino = () => {
  return (
    <EntertainmentLayout
      category="Segreteria Organizzativa"
      title="Mannarino &#10;Tour Al Monte Live"
      bannerImage="mannarino.jpg"
      metaDescription="Mannarino a Spoleto per una tappa del tour “Al Monte Live”. L’artista ha ricevuto tra i vari riconoscimenti, Premio Gaber e Premio Siae."
      description="Mannarino affronta un nuovo viaggio nel quale l’uomo è ancora una
      volta al centro «Al Monte ci vanno i Santi e i Briganti, i
      rivoluzionari e gli asceti. È un luogo sicuro, perché libero da
      recinti e da bandiere, da costruzioni e campi ordinati, e di notte
      ci si può nascondere da ciò che non è umano»."
      contents={[{
        date:`Luglio 2014
        SPOLETO, Piazza del Duomo
        `,
        text:`Insieme ai nuovi brani l’artista esegue i suoi maggiori successi,
        raccontando di una ribellione sgangherata, quella dell’osteria e del
        vino (con l’album d’esordio “Bar della Rabbia”) e dipingendo il
        ritratto della metropoli e della strada dove i personaggi che ce la
        fanno sono pochi e il ruolo della donna è centrale (con l’album
        “Supersantos”).
        
        I riconoscimenti alla carriera di Mannarino: Premio Gaber e
        Premio Siae come miglior artista emergente. Due partecipazioni al
        Concertone del Primo Maggio. Un tour negli Stati Uniti e in Canada
        (insieme a Subsonica e Negrita). Autore dell’arrangiamento della
        sigla di Ballarò e della colonna sonora del film di Rolando Ravello
        “Tutti contro Tutti”, vincitrice al Magna Grecia Film Festival.`,
        //image: "mannarino.jpg"  
      }]}
    />
  )
}

export default Mannarino
