import React from "react"
import EntertainmentLayout from "../../../components/entertainmentLayout/entertainmentLayout.js"

const LifeInUmbria = () => {
  return (
    <EntertainmentLayout
      category="Segreteria Organizzativa"
      title="Life in Umbria... &#10;lo spettacolo prende vita"
      bannerImage={[
        {id: 1, filename:"life-in-umbria-bonolis-intervista-niccolo-fabi.jpg", alt:"Life in Umbria: Paolo Bonolis intervista Niccolò Fabi"},
        {id: 2, filename:"bonolis-presenta-life-in-umbria.jpg", alt:"Paolo Bonolis presente Life in Umbria"},
        {id: 3, filename:"life-in-umbria-bonolis-intervista.jpg", alt:"Life in Umbria: Paolo Bonolis conduce le interviste"},
        {id: 4, filename:"life-in-umbria-bonolis-coso.jpg", alt:"Life in Umbria: Paolo Bonolis intervista"},
        {id: 5, filename:"life-in-umbria-bonolis-consegna-rose.jpg", alt:"Life in Umbria: Paolo Bonolis consegna delle rose"}
      ]}
      metaDescription="ACME decide di produrre insieme ai direttori artistici M.F. Afferrante e F. Mauceri un evento unico per Spoleto condotto da Paolo Bonolis. Ospite: Niccolò Fabi"
      description="Dopo l’esperienza dell’anno precedente a Gubbio, ACME decide di produrre un evento unico per la città di Spoleto. In sinergia con i direttori artistici di Life in… e Paolo Bonolis 
      vengono scelti gli ospiti, la linea artistica della serata e il piano di comunicazione e d’immagine.
      "
      contents={[{
        date:`Dicembre 2010
        Spoleto, Teatro Nuovo “Gian Carlo Menotti”
        Serata di Gala Palazzo Collicola`,
        text: `        
        Per l’occasione viene organizzata una serata di gala nel piano 
        Nobile di Palazzo Collicola, sede della Galleria d’Arte Moderna, 
        durante la quale vengono raccolti fondi per l’Associazione 
        Ce.R.S. Onlus, per il progetto “Adotta un Angelo”, 
        costituita per volontà dei Dott. Renato Berardinelli 
        (Ospedale Pediatrico Bambin Gesù di Roma) e Nicola Moricca. 
        
        Life in Umbria una manifestazione di immagini, 
        musica e parole condotta da Paolo Bonolis, e ideata da 
        Michele Francesco Afferrante e Filippo Mauceri 
        (autori del programma televisivo Il Senso della Vita).
        
        È un’occasione per stare insieme, nel segno della leggerezza, 
        della musica di qualità e del buon umore, ma in particolare 
        per incontrare ed ascoltare personaggi provenienti dal mondo 
        della cultura e dello spettacolo con l’obiettivo di dare vita 
        a momenti di grandi intensità.
        
        Un momento condiviso di riflessione dove è protagonista una 
        parola forte, parola che non ha bisogno di altro, che cattura 
        l’attenzione per la tensione che riesce a creare. 
        Non parole tra le tante ma parole di una vita.
       
        Life in… è una manifestazione di immagini, musica e parole 
        ideata da Michele Francesco Afferrante e Filippo Mauceri 
        (autori del programma televisivo Il Senso della Vita) condotta 
        da Paolo Bonolis. I premi Il senso di una vita sono conferiti 
        a donne e uomini che si distinguono per la loro riconosciuta 
        solidarietà e ricchezza intellettuale, per la loro decisa azione 
        nella società, per la freschezza con cui hanno saputo 
        mantenere vivo il loro sguardo sul mondo.
      
        In questa occasione vengono premiati: Don Luigi Ciotti, 
        Remo Bodei, Dacia Maraini, Marcia Theophilo`,
        }]}
      guests={
        <>
          <b>Ospiti</b>
          <br />
          Niccolò Fabi,
          <br />
          Stefano Di Battista Jazz Quartet & Nicky Nicolai,
          <br />
          Luciano Biondini
        </>
      }
      firstAuthor={
        <>
          <b>Veronica Montanino</b>
          <br />
          <p>Autore del Manifesto Life In 2010</p>
        </>
      }
      secondAuthor={
        <>
          <b>Enrico Morbidoni</b>
          <br />
          <p>Autore del Premio Life In</p>
        </>
      }
    />
  )
}

export default LifeInUmbria
