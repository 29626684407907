import React from "react"
import TechSoftLayout from "../../../components/techSoftLayout/techSoftLayout.js"

const TodiAppyDays = () => {
  return (
    <TechSoftLayout
      title="Todi Appy Days&#10;International APP Festival"
      descriptionType="customAndWifi"
      metaDescription="Per Todi Appy Days abbiamo realizzato infrastruttura WiFi e garantito servizi di autenticazione clienti, connessione."
      contents={[{
        date:`Committente: Sedicieventi - Div. Gioform Srl
        Settembre 2015
        Todi
        
        `,
        text: `
        Il primo grande evento completamente gratuito e aperto a tutti
        dedicato al mondo delle App e del mobile.
        
        Piazza del Popolo, i Portici e i Voltoni del Palazzo Comunale sono
        state le location che hanno ospitato grandi e piccole realtà legate
        al mondo delle applicazioni per dispositivi mobili, in questa
        manifestazione che ha fatto loro da vetrina, offrendo grande
        visibilità nazionale e internazionale.`,
        image: "todi.png"
        },
        {
        text: {
            partOne: `Esigenze del cliente
              
            • Copertura WiFi delle aree evento e sale convegni
            • Rendere estremamente semplice e veloce la procedura di autenticazione per gli ospiti.`,
            partTwo: `Le nostre soluzioni

            • Realizzazione infrastruttura rete WiFi per stand espositori, spazi dedicati ai meeting e spettacolo
            • Fornitura connettività dedicata
            • Captive portal Connect@You per l’autenticazione del pubblico e degli espositori
            • Servizio di assistenza`
          }
        }
      ]}
    />
  )
}

export default TodiAppyDays
