import React from "react"
import TechSoftLayout from "../../../components/techSoftLayout/techSoftLayout.js"

const FerraraSharingFestival = () => {
  return (
    <TechSoftLayout
      title="Ferrara Sharing &#10;Festival"
      descriptionType="customAndWifi"
      metaDescription="Per Ferrara Sharing Festival abbiamo realizzato infrastruttura WiFi e garantito servizi di autenticazione clienti, connessione."
      contents={[{
        date:`Committente: Sedicieventi - Div. Gioform Srl
        Maggio 2016
        Ferrara
        
        `,
        text: `
        Il Ferrara Sharing Festival è un evento interamente dedicato
        all’economia collaborativa.        
        Nei tre giorni di festival si sono alternernate Plenarie, momenti
        che hanno l’obiettivo di favorire un costruttivo dialogo tra forze e
        organizzazioni che operano a più livelli nel mondo associazionistico
        e nell’innovazione sociale. Si parlerà con gli esperti di nuove
        economie, nuove professioni e piattaforme e servizi innovativi.`,
        image: "ferrara.png"
        },
        {
        text: {
            partOne: `Esigenze del cliente

            • Copertura WiFi dell’area evento
            • Rendere estremamente semplice e veloce la procedura di autenticazione per gli ospiti`,
            partTwo: `Le nostre soluzioni

            • Realizzazione infrastruttura rete WiFi per gli spazi dedicati ai meeting
            • Fornitura connettività dedicata
            • Captive portal Connect@You per l’autenticazione del pubblico e degli addetti ai lavori
            • Servizio di assistenza`
          }
        }
      ]}
    />
  )
}

export default FerraraSharingFestival
