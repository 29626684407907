import React, {useContext, useMemo} from "react"
import { motion, AnimatePresence } from "framer-motion"
import MenuItem from "./menuItem.js"
import { ThemeContext } from '../../context/ThemeContext'

import "./style.scss"
import {usePrevious} from "../../helper";



const Menu = ({menu}) => {
  const theme = useContext(ThemeContext);


  const childrenLength = useMemo(() => menu?.children?.length||0, [menu?.children])
  const previousChildrenLength = usePrevious(childrenLength);



  function close() {
    theme.setCurrentMenu(false)
  }

  return (
    <motion.ul
      animate={{ height: childrenLength * 70 }}
      transition={{ duration: 0.3, delay: previousChildrenLength <= childrenLength ? 0 : previousChildrenLength * 0.1 }}
      className={menu && menu.children ? "open" : "closed"}
      onMouseLeave={close}
      id="submenu"
    >
      <AnimatePresence>
        {(menu?.children||[]).map((submenu, index) => (
            <MenuItem key={submenu.id} index={index + 1} {...submenu} />
          ))}
      </AnimatePresence>
    </motion.ul>
  )
}

export default Menu
