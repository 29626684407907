import React from "react"
import EntertainmentLayout from "../../../components/entertainmentLayout/entertainmentLayout.js"

const CochiERenato = () => {
  return (
    <EntertainmentLayout
      category="Segreteria Organizzativa"
      title="Cochi e Renato &#10;Finché c'è la salute"
      bannerImage="cochierenatoBanner.jpg"
      metaDescription="Ritorna a Spoleto il divertente mondo di Cochi e Renato (Pozzetto) con lo spettacolo “Finché c'è la salute”, in occasione della Stagione di Teatro Comico organizzata da ACME."
      description="Tempi teatrali perfetti, un continuo gioco delle parti tra Cochi e
      Renato con protagonista talvolta l'uno e talvolta l'altro e dietro
      la cui leggerezza regna sovrana la ricerca del dettaglio e della
      perfezione."
      contents={[{
        date:`Novembre 2011
        Spoleto, Teatro Nuovo Gian Carlo Menotti
        `,
        text:`Ritorna a Spoleto il divertente mondo di Cochi e Renato con la sua
        comicità intrisa di assurdo. In scena con lo spettacolo “Finché c'è
        la salute”, in occasione della Stagione di Teatro Comico organizzata
        dall’associazione culturale Entertainment e da ACME Produzioni.               
        Nel titolo è racchiuso un messaggio che conferma il forte desiderio
        di Cochi e Renato di continuare ad intrattenere, divertire,
        commuovere e far sognare il pubblico finché la “salute glielo
        permetterà” e, forse, in questo messaggio c’è anche un “pizzico” di
        scaramanzia.`,
        //image: "cochirenato.jpg"  
      }]} 
    />
  )
}

export default CochiERenato
