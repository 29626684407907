import React, { useContext, useEffect } from "react"
import Header from "../components/header/header.js"
import Description from "../components/description/description.js"
import { ThemeContext } from "../context/ThemeContext"
//import Tile from "../components/tile/tile.js"
//import duotoneImage from "../components/duotoneImage/duotoneImage"
import '../assets/styles/pages/_index.scss'

const IndexPage = () => {
  const theme = useContext(ThemeContext)

  useEffect(() => {
    theme.setBackGroundColor("white")
    theme.setOriginPage({ label: "Home", to: "/" })
    theme.setVisibleFooter(true)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Header
        title="Welcome to ACME"
        bannerImage="home-banner"
        metaDescription="ACME nasce nel mondo dell’intrattenimento;
        nel tempo ci siamo occupati di diverse attività come lo sviluppo
        di software e APP puntando sempre all’eccellenza."
      />
      <Description>
        <div className="columns m-0">
          <div className="column is-4-fullhd is-6-desktop is-6-tablet is-offset-6">
            <h2>
              <i>We keep our promises</i>
            </h2>
            <p>
              Chi non ricorda tra i mitici personaggi dei Looney Tunes, Wile E.
              Coyote e Beep Beep, che con le loro avventure hanno segnato intere
              generazioni?
              <br />
              <br />
              Al super genio Wile E. Coyote e alla sua instancabile
              determinazione nel catturare Beep Beep, fa da cornice un mondo
              pieno di razzi, dinamite, esplosioni e piani tanto geniali quanto
              fallimentari, tutti con il marchio ACME Inc.
              <br />
              <br /> La Acme Corporation è un’azienda immaginaria dell'universo
              Looney Tunes e non solo (il nome viene utilizzato genericamente in
              molti altri cartoni animati, film e serie tv); i prodotti del
              laboratorio sono congegni estremamente complessi che hanno la
              tendenza a funzionare male o per nulla.
              <br />
              <br />
              La scelta dell’acronimo, volutamente ironica, deriva dal greco
              ακμή che in italiano significa eccellenza.
              <br />
              <br /> <b>A</b> <b>C</b>ompany <b>M</b>aking <b>E</b>verything,
              ovvero un’azienda che produce qualsiasi cosa. <br />
              <br /> Da qui l’idea, ci è venuto naturale chiamarci ACME
              Produzioni.
              <br />
              <br />
              Augurandoci la tenacia di Wile e la scaltrezza di Beep Beep, dai
              nostri ricordi d’infanzia abbiamo tirato fuori il nome della
              nostra società.
              <br />
              <br />
              La nostra di ACME, nasce nel 2008, nel mondo dell’intrattenimento;
              e come vuole il suo nome, nel tempo ci siamo occupati di attività,
              tanto diverse tra loro, che se non hanno convinto i più scettici
              di sicuro hanno conquistato gli audaci, che hanno creduto in noi e
              nella nostra instancabile ricerca dell’eccellenza.
              <br />
              <br /> A chi già ci conosce, speriamo di aver strappato un
              sorriso;
              <br />
              <br /> A chi invece entra per la prima volta nel mondo ACME, siamo
              certi aver seminato un dubbio; e dal dubbio, si sa, nasce la
              curiosità, che è anticamera della libertà, e apre la mente alla
              conoscenza.
              <br />
              <br /> <i>«Solo i curiosi sono davvero innovativi».</i>
              <strong>
                <i> Dan Brown</i>
              </strong>
            </p>
          </div>
        </div>
      </Description>      
    </div>
  )
}

export default IndexPage
