import React from "react"
import Marquee from "../marquee/marquee.js"
import "./style.scss"

const Post = ({ title, index, author, date, description, path, image}) => (
  <div className="post" id={path}>
    <Marquee text={title} speed="very-slow" />
    {index % 2 === 0 ? (
      <div className="columns">
        <div className="column is-3">
          <div className="post-image">
          <img src={image} alt={title} />
          </div>
        </div>
        <div className="column is-6">
          <p
            dangerouslySetInnerHTML={{ __html: description }}
            className="post-description"
          ></p>
        </div>
      </div>
    ) : (
      <div className="columns invert">
        <div className="column is-6 is-offset-3 ">
          <p
            dangerouslySetInnerHTML={{ __html: description }}
            className="post-description"
          ></p>
        </div>
        <div className="column is-3">
          <div className="post-image">
          <img src={image} alt={title} />
          </div>
        </div>
      </div>
    )}
  </div>
)

export default Post
