import React, { useContext } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { ThemeContext } from "../../context/ThemeContext"
import { Link } from "gatsby"
import "./style.scss"

const MenuMobile = ({ toggle, MENUS }) => {
  const theme = useContext(ThemeContext)

  function toggleMenu(toggle) {
    theme.setToggleMobileMenu(!toggle)
    theme.setCurrentSubMobileMenu(false)
  }

  function toggleSubMenu(submenu) {
    if (submenu.children) {
      theme.setCurrentSubMobileMenu(submenu)
      if (theme.currentSubMobileMenu.id === submenu.id) closeMenu()
    } else {
      closeMenu()
    }
  }

  function closeMenu() {
    theme.setToggleMobileMenu(false)
    theme.setCurrentSubMobileMenu(false)
  }

  const variants = {
    init: { height: "0px", borderBottom: "0px" },
    open: {
      height: "100vh",
      borderBottom: "1px solid",
      transition: { duration: 0.7 },
    },
    closed: {
      height: "0px",
      borderBottom: "0px",
      transition: { delay: 0.2, duration: 0.7 },
    },
  }

  const variantsSub = {
    init: {
      x: -100,
      opacity: 0,
      textAlign: "center",
      height: "0px",
      borderBottom: "0px",
      marginTop: "0px",
    },
    open: {
      x: 0,
      opacity: 1,
      height: "100%",
      borderBottom: "0px",
      marginTop: "10px",
      transition: { delay: 0.2, duration: 0.2 },
    },
    closed: {
      x: 100,
      opacity: 0,
      height: "0px",
      borderBottom: "0px",
      marginTop: "0px",
      transition: { delay: 0.2, duration: 0.2 },
    },
  }

  return (
    <>
      <button
        className={`menuMobileIcon ${theme.backGroundColor}`}
        onClick={() => toggleMenu(toggle)}
        aria-label="Toggle Menu"
      >
        <motion.div
          animate={
            theme.toggleMobileMenu
              ? { transform: "rotate(45deg)" }
              : { transform: "rotate(0deg)" }
          }
        ></motion.div>
        <motion.div
          animate={theme.toggleMobileMenu ? { opacity: 0 } : { opacity: 1 }}
          transition={{ duration: 0.1 }}
        ></motion.div>
        <motion.div
          animate={
            theme.toggleMobileMenu
              ? { transform: "rotate(-45deg)" }
              : { transform: "rotate(0deg)" }
          }
        ></motion.div>
      </button>
      <motion.ul
        className="menuMobileContainer"
        initial={"init"}
        animate={toggle ? "open" : "closed"}
        variants={variants}
      >
        {toggle &&
          MENUS.map(menu => (
            <motion.li
              className="menuMobile"
              initial={{
                x: -100,
                opacity: 0,
                textAlign: "center",
                borderBottom: "0px",
                paddingBottom: "0px",
                paddingTop: "0px",
              }}
              animate={{
                x: 0,
                opacity: 1,
                borderBottom: "1px solid",
                paddingBottom: "10px",
                paddingTop: "10px",
              }}
              exit={{
                x: 100,
                opacity: 0,
                borderBottom: "0px",
                paddingBottom: "0px",
                paddingTop: "0px",
              }}
              transition={{ delay: 0.5, duration: 0.2 }}
              key={menu.id}
            >
              <Link
                key={menu.id}
                to={
                  theme.currentSubMobileMenu.id === menu.id || !menu.children
                    ? menu.to
                    : "."
                }
                onClick={() => toggleSubMenu(menu)}
              >
                {menu.label}
              </Link>
              <AnimatePresence exitBeforeEnter>
                <motion.ul key={menu.id}>
                  {menu.children &&
                    menu.children.map(submenu => (
                      <motion.li
                        className="subMenuMobile"
                        initial={"init"}
                        animate={
                          menu.id === theme.currentSubMobileMenu.id
                            ? "open"
                            : "closed"
                        }
                        variants={variantsSub}
                        key={submenu.id}
                      >
                        <Link
                          key={submenu.id}
                          to={submenu.to}
                          onClick={closeMenu}
                        >
                          {submenu.label}
                        </Link>
                      </motion.li>
                    ))}
                </motion.ul>
              </AnimatePresence>
            </motion.li>
          ))}
        {toggle && (
          <motion.div
            className="socialMobile"
            initial={{
              x: -100,
              opacity: 0,
              textAlign: "center",
              paddingBottom: "0px",
              paddingTop: "0px",
            }}
            animate={{
              x: 0,
              opacity: 1,
              paddingBottom: "10px",
              paddingTop: "50%",
            }}
            exit={{
              x: 100,
              opacity: 0,
              paddingBottom: "0px",
              paddingTop: "0px",
            }}
            transition={{ delay: 0.5, duration: 0.2 }}
          >
            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/acmeproduzioni/">Fb</a>
            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/acmeproduzioni_official/">Ig</a>
            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/acme-produzioni/">In</a>
          </motion.div>
        )}
      </motion.ul>
    </>
  )
}

export default MenuMobile
