import React from "react"

import { Link } from "gatsby"
import './style.scss'
import { ImageFactory } from "../../helper.js"

const DuotoneImage = ({ filename, to, alt, type}) => 
{
  const ImageHover = ImageFactory(type);

  function getImage()
  {
    

    return (<div
      className="image-change-on-hover with-filter"
      role="button" 
      tabIndex={0}
      >       
      <ImageHover 
        alt={alt}
        filename={filename} />   
    </div>)



  }

  if(to)
    return (
      <Link to={to}>{getImage()}</Link>
    )

  return getImage()
}

export default DuotoneImage
