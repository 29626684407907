import React from "react"
import { Link } from "gatsby"

// styles
const pageStyles = {
    color: "#232129",
    padding: "96px",
    fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
    fontFamily: "Helvetica_Neue_Bold",
    fontSize: "5.4rem",
    letterSpacing: "-5px",
    whiteSpace: "pre-line",
}

const paragraphStyles = {
    marginBottom: 48,
}

// markup
const NotFoundPage = () => {
    return (
        <main style={pageStyles}>
            <title>Not found</title>
            <h1 style={headingStyles}>404: Page not found</h1>
            <p style={paragraphStyles}>
                Sorry{" "}
                <span role="img" aria-label="Pensive emoji">
                    😔
                </span>{" "}
                we couldn’t find what you were looking for.
                <br />               
                <br />
                <Link to="/">Go home</Link>.
            </p>
        </main>
    )
}

export default NotFoundPage
