import React from "react"
import TechSoftLayout from "../../../components/techSoftLayout/techSoftLayout.js"
import Image from "../../../components/image/image.js"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay } from "swiper"

const Ptn = () => {
  SwiperCore.use([Autoplay])
  const FIRSTCAROUSEL = [
    {
      id: 1,
      filename: "ptn-con-sfondo-bianco.jpg",
      alt: "Pinguini Tattici Nucleari su sfondo bianco",
    },
    {
      id: 2,
      filename: "ptn-con-sfondo-celeste.jpg",
      alt: "Pinguini Tattici Nucleari su sfondo celeste",
    },
    {
      id: 3,
      filename: "ptn-con-sfondo-nero.jpg",
      alt: "Pinguini Tattici Nucleari su sfondo nero",
    },
  ]

  const SECONDCAROUSEL = [
    {
      id: 1,
      filename: "ptn-screen-app.png",
      alt: "PTN Screenshots APP",
    },
    {
      id: 2,
      filename: "ptn-con-sfondo-rosa.jpg",
      alt: "Pinguini Tattici Nucleari su sfondo celeste",
    },
  ]

  return (
    <TechSoftLayout
      title="Pinguini Tattici Nucleari &#10;#machilavrebbemaidetto Tour"
      descriptionType="customAndWifi"
      description={`Per BPM Concerti srl e per la band Pinguini Tattici Nucleari abbiamo sviluppato la APP ufficiale del tour 2020 #machilavrebbemaidetto. `}
    >
      <div className="section" />
      <div className="columns custom m-1">
        <div className="column custom">
          <p>
            <b>
              Dopo il loro successo a Sanremo 2020 e pianificato il tour viene
              commissionato ad ACME lo sviluppo della APP: un contenitore di
              informazioni sulle date del tour e su dove poter acquistare i
              biglietti. È anche uno strumento di interazione durante lo show,
              tra la band e gli spettatori del concerto.
            </b>
          </p>
          <br />
          <p>
            <br /> Esigenze del cliente
            <br />
            <br />• APP dedicata al #machilavrebbemaidetto tour con diverse
            funzionalità: calendario date, acquisto biglietti e altre
            informazioni sulla location
            <br />• Sviluppo di un gioco dedicato alla band
            <br />• Strumento di interazione tra band e pubblico presente
          </p>
          <br />
          <p>
            Le nostre soluzioni
            <br />
            <br />• Ideazione e realizzazione della APP con sistema operativo
            iOS e Android per tenere aggiornati i fan sulle date ed i luoghi
            delle tappe, un countdown per l’inizio del tour e per ogni singola
            data, informazioni su location, acquisto biglietti con link
            dedicato, e chat interna
            <br />• Realizzazione del gioco dedicato alla band
            <br />• Sviluppo di un sistema di interazione tra pubblico e
            artista, gestito tramite bluetooth; durante l’esecuzione di alcuni
            brani lo schermo del telefono cambia colore seguendo una sequenza
            che permette a coloro che hanno scaricato la APP di essere parte
            integrante dello show
            <br />• Servizio di assistenza dedicata per tutta la durata del tour
          </p>
          <br />
        </div>
        <div className="column custom">
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            grabCursor={true}
            loop={true}
            autoplay={{ delay: 2500, disableOnInteraction: false }}
          >
            {FIRSTCAROUSEL.map(i => (
              <SwiperSlide key={i.id}>
                <Image filename={i.filename} alt={i.alt} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="section" />
      <div className="columns custom m-1 invert">
        <div className="column is-6-tablet is-12-mobile">
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            grabCursor={true}
            loop={true}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
          >
            {SECONDCAROUSEL.map(i => (
              <SwiperSlide key={i.id}>
                <Image filename={i.filename} alt={i.alt} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="column is-6-tablet is-12-mobile">
          <p>
            La APP è disponibile su Apple Store e Google Play, costantemente
            aggiornata secondo esigenze del cliente
          </p>
          <div className="section" />
          <div className="columns goToApp">
            <div className="column">
              <a
                rel="noreferrer"
                href="http://onelink.to/xd5h9s"
                target="_blank"
              >
                <button className="goTo">Vai all'APP</button>
              </a>
            </div>
            <div className="column">
              <a
                target="_blank"
                rel="noreferrer"
                href="http://onelink.to/xd5h9s"
                style={{ width: "590px", maxWidth: "50%" }}
              >
                <Image filename="ptn.png" alt="Pinguini Tattici Nucleari" />
              </a>
            </div>
          </div>
          <div className="section"></div>
        </div>
      </div>
    </TechSoftLayout>
  )
}

export default Ptn
