import React from "react"
import EntertainmentLayout from "../../../components/entertainmentLayout/entertainmentLayout.js"
import Image from "../../../components/image/image.js"

const ILoveSpoleto = () => {
  return (
    <>
      <EntertainmentLayout
        category="Segreteria Organizzativa"
        title="I Love Spoleto 2018"
        bannerImage="i-love-spoleto-banner"
        metaDescription="I LOVE SPOLETO una rassegna per il periodo estivo che ha visto tra gli artisti: G. Giannini, C. De Sica, Caparezza e Kataklò."
        description="Nel 2018 ACME decide di organizzare una rassegna per il periodo
      estivo proponendo un’offerta artistica variegata che abbracci un
      pubblico vasto ed eterogeneo. Il Teatro Romano cornice perfetta per
      la danza, Piazza Duomo elegante platea per ospitare gli appuntamenti
      di varietà e musica, mentre per animare i più giovani si è pensato
      allo Stadio Comunale. Dalla sinergia con le diverse produzioni
      nascono le scelte tecniche, artistiche e tutte le attività
      necessarie per portare gli artisti sul palcoscenico. Eventi unici,
      studiati per esaltare lo spettacolo e le location che li ospitano."
      contents={[{
        date:`Luglio - Agosto 2018
        Spoleto, Teatro Romano
        Piazza Duomo
        Stadio Comunale                
        `
      }]} >
        <>
            <div className="container ILS">
              <div className="columns">
                <div className="column">
                  <div>
                    <Image
                      filename="giannini.jpg"
                      alt="Giancarlo Giannini Le parole note 2018"
                    />
                  </div>
                </div>
                <div className="column">
                  <div className="ILSDescription">
                    <br /> <b>Giancarlo Giannini | Le parole note</b>
                    <br />
                    <br />
                    Il recital di Giancarlo Giannini è un singolare incontro di
                    letteratura e musica.
                    <br />
                    Grandi emozioni, varie atmosfere. Un Unico Spettacolo.
                    <br />
                    <br />
                    L'attore particolarmente esperto nell'analisi della parola
                    recita una serie di brani e poesie al grande pubblico da
                    Pablo Neruda, Garcia Lorca, Marquez, ai più classici come
                    Shakespeare, Angiolieri, Salinas.
                    <br />
                    Vari autori ed un unico tema: L’amore, la donna, la
                    passione…La vita!
                    <br />
                    Giancarlo Giannini e la sua recitazione, la sua voce calda e
                    penetrante conducono gli spettatori in “atmosfere”,
                    mistiche, malinconiche, amorose, ed ironiche in un viaggio
                    dal 200’ fino ad arrivare ai giorni nostri.
                    <br />
                    <br /> Sul palco:
                    <br />
                    Marco Zurzolo Quartet
                    <br />
                    Special guest Luciano Biondini
                  </div>
                </div>
              </div>
            </div>
            <div className="container ILS">
              <div className="columns invert">
                <div className="column">
                  <div className="ILSDescription">
                    <br /> <b>Mediterranea | Il balletto italiano che ha conquistato il mondo</b>                    
                    <br />
                    <br />                    
                    Mediterranea nasce nel 1993 per il Balletto di Toscana ed
                    ottiene subito uno strepitoso successo. Per i suoi quindici
                    anni, nel 2008, viene ripreso e rimontato per la compagnia
                    del Teatro alla Scala.
                    <br />
                    <br />
                    Per l’occasione il coreografo Bigonzetti non si è limitato
                    ad una semplice ripresa dell’originale ma attua una vera e
                    propria riscrittura coreografica, facendo assumere alla
                    coreografia un impatto fisico e visivo ancora maggiore e
                    facendole acquistare il sapore della novità.
                    <br />
                    Mediterranea non è un lavoro narrativo ma evocativo: una
                    vera circumnavigazione del Mediterraneo, attraverso la
                    musica delle culture che vi si affacciano e che fanno
                    viaggiare lo spettatore nello spazio e nel tempo.
                    <br />
                    Tutta la danza è costruita nella coesistenza di emozioni
                    opposte: movimenti energetici e scanditi si alternano a
                    passaggi estremamente lirici.
                  </div>
                </div>
                <div className="column">
                  <div>
                    <Image
                      filename="mediterranea.jpg"
                      alt="Mediterranea Il balletto italiano che ha conquistato
                        il mondo 2018"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="container ILS">
              <div className="columns">
                <div className="column">
                  <div>
                    <Image filename="kataklo.jpg" alt="Kataklò Readys 2018" />
                  </div>
                </div>
                <div className="column">
                  <div className="ILSDescription">
                    <br /> <b>Kataklò | Ready</b>
                    <br />
                    <br />
                    Uno spettacolo estivo per il grande pubblico e le famiglie
                    che valorizzato dall’ambientazione storica e unica della
                    location acquista una dimensione poetica irripetibile
                    altrove.
                    <br />
                    <br />
                    Kataklò Athletic Dance Theatre propone all’interno del
                    magnifico e suggestivo contenitore del Teatro Romano di
                    Spoleto, Ready, uno show appositamente pensato e costruito
                    da Giulia Staccioli per questo luogo di grande impatto
                    scenico e per il suo numeroso pubblico.
                    <br />
                    Lo spettacolo si compone di coreografie tratte dalle
                    produzioni Eureka (2016), Puzzle (2012) e Play (2008)
                    mantenendo la linea dinamica, atletica, poetica ed originale
                    che ha reso Kataklò la compagnia di athletic theatre
                    italiana più amata e richiesta al mondo.
                  </div>
                </div>
              </div>
            </div>
            <div className="container ILS">
              <div className="columns invert">
                <div className="column">
                  <div className="ILSDescription">
                    <br /> <b>Christian De Sica | Christian Racconta Christian De Sica</b>                    
                    <br />
                    <br />
                    Una serata speciale con Christian De Sica che – accompagnato
                    da una spumeggiante big band di 18 elementi, con parole e
                    canzoni come in un grandissimo salotto di amici – si
                    racconta al pubblico sotto le stelle, nella fantastica
                    cornice della Piazza Duomo di Spoleto.
                    <br />
                    <br />
                    Accanto a lui, a guidare questo fantastico ironico ed
                    esilarante viaggio, Pino Strabioli, giornalista, regista e
                    attore che in questa serata veste i panni di cerimoniere
                    della serata.
                    <br />
                    <br />
                    Un De Sica inedito a allo specchio, un viaggio musicale che
                    ci accompagna dai più grandi classici americani del suo
                    amato swing, ad arrivare ai grandi successi italiani dei più
                    grandi autori, tra cui Lelio Luttazzi.
                    <br />
                    Una big band unica, guidata ed orchestrata dal Maestro Marco
                    Tiso, con al pianoforte il Maestro Riccardo Biseo.
                    <br />
                    <br />A tessere la serata monologhi irresistibili e storie
                    commoventi che riguardano il grande papà Vittorio,
                    l’infanzia ed il cinema di Natale che ha accompagnato la
                    storia d’Italia negli ultimi trent’anni. Christian – grazie
                    al suo talento raro – passa dall’incontro con un’attempata
                    Wanda Osiris, al ricordo del liceo con Carlo Verdone,
                    attraverso i pezzi swing italiani e stranieri.
                  </div>
                </div>
                <div className="column">
                  <div>
                    <Image filename="desica.jpg" alt="Christian Racconta Christian De Sica 2018" />
                  </div>
                </div>
              </div>
            </div>
            <div className="container ILS">
              <div className="columns">
                <div className="column">
                  <div>
                    <Image
                      filename="caparezza.jpg"
                      alt="Caparezza Prisoner 709 Tour 2018"
                    />
                  </div>
                </div>
                <div className="column">
                  <div className="ILSDescription">
                    <br /> <b>Caparezza | Prisoner 709 Tour</b>
                    <br />
                    <br />
                    Che dire... Per me la vita di un’artista è meno interessante
                    della sua opera ma a corollario vi lascio comunque delle
                    informazioni utili perché la sostanza va, la forma resta.
                    <br />
                    <br />
                    Mi chiamo Michele Salvemini, sono figlio di un operaio e di
                    una maestra. Il mio nonno paterno era falegname, quello
                    materno era contadino. Io sono diventato cantante, non me ne
                    vogliano. Sono nato nel 1973, precisamente il 9 Ottobre,
                    come John Lennon con il quale ho in comune solo questa data
                    e... chissà… forse lo stesso epilogo.
                  </div>
                </div>
              </div>
            </div>
          </>
      </EntertainmentLayout>
    </>
  )
}

export default ILoveSpoleto
