import React from "react"
import EntertainmentLayout from "../../../components/entertainmentLayout/entertainmentLayout.js"

const Venditti = () => {
  return (
    <EntertainmentLayout
      category="Segreteria Organizzativa"
      title="Antonello Venditti &#10;Special Event live"
      bannerImage="venditti.jpg"
      metaDescription="Un concerto di Antonello Venditti per ripercorre la sua carriera artistica (Alta marea, Giulio Cesare, Roma Roma, Che tesoro che sei, Notte prima degli esami)."
      description="Un concerto durante il quale Antonello Venditti ripercorre la sua
      carriera artistica, attraverso canzoni inedite e grandi successi."
      contents={[{
        date:`Agosto 2011 
        Preci, Area Verde Concerto 
        `,
        text:` Alta marea, Ci vorrebbe un amico, 21 modi per dirti ti amo, Amici
        mai, Piero e Cinzia, Questa insostenibile leggerezza dell'essere,
        Sotto il segno dei pesci, Roma capoccia, Ogni volta, Giulio Cesare,
        Benvenuti in Paradiso, Stella, Lacrime di pioggia, Sora Rosa,
        Dimmelo tu cos'è, Raggio di Luna, Il compleanno di Cristina, Le cose
        della vita, Compagno di scuola, Miraggi, Modena, Bomba o non bomba,
        Roma Roma, In questo mondo di ladri, Mitico amore, Ricordati di me,
        Notte prima degli esami, Peppino, Segreti, Che tesoro che sei,
        Centocittà, Le ragazze di Monaco, Una stupida e lurida storia
        d'amore, Lilly, Mio padre ha un buco in gola, Sara, Buona domenica….`,
       //image: "vendittiDes.jpg"  
      }]}
    />
  )
}

export default Venditti
