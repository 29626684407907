import React from "react"
import EntertainmentLayout from "../../../components/entertainmentLayout/entertainmentLayout.js"
import "../../../components/entertainmentLayout/style.scss"

const TeatroComico = () => {
  return (
    <EntertainmentLayout
      category="Segreteria Organizzativa"
      title="Teatro Comico"
      bannerImage="battista.jpg"
      metaDescription="La rassegna itinerante Teatro Comico con protagonisti provenienti da Zelig e Colorado Cafè: Luca e Paolo, D. Vergassola, M. Hunziker, M. Marzocca, G. Covatta."
      description="ACME impiega tutte le sue doti nella produzione del Teatro Comico la rassegna itinerante che ha toccato i principali teatri dell’Umbria e non solo, nell’arco di un’intera stagione teatrale.
      I protagonisti di fama nazionale, provengono dalle trasmissioni televisive Zelig e Colorado Cafè, altri sono già affermati da anni nel mondo teatrale e televisivo e conosciuti dal grande pubblico."
    >
      <div className="entertainment-contents">
        <div className="entertainment-content columns p-2">
          <div
            className={`entertainment-content__text column is-5 is-offset-7 is-one-third-desktop is-half-tablet is-full-desktop`}
          >
           <b>{`Stagione 2009/2010`}</b> 
         
         {`
         
        Giuseppe Giacobazzi in Una vita da paura | Foligno, Auditorium San Domenico Mercoledì 20 gennaio

        Giobbe Covatta in Trenta | Gubbio, Teatro Comunale Venerdì 29 gennaio

        Luca e Paolo in La passione secondo Luca e Paolo | Cagli, Teatro Comunale Venerdì 5 febbraio

        Luca e Paolo in La passione secondo Luca e Paolo | Spoleto, Teatro Nuovo Sabato 6 febbraio

        Marco “BAZ” Bazzoni in a BAZ live | Narni, Teatro Comunale Giovedì 18 febbraio

        Marco “BAZ” Bazzoni in a BAZ live | Foligno, Auditorium San Domenico Giovedì 25 febbraio

        Paolo Cevoli in Recital 2010 | Terni, Teatro Verdi Venerdì 12 marzo

        Marco Marzocca in Recital 2010 | Città della Pieve, Teatro Comunale Sabato 13 marzo

        I Turbolenti in Siamo poveri di mezzi | Narni, Teatro Comunale Giovedì 18 marzo

        Marco Marzocca in Recital 2010 | Cagli, Teatro Comunale Venerdì 19 marzo

        I Turbolenti in Siamo poveri di mezzi | Gubbio, Teatro Comunale Venerdì 26 marzo

        Maurizio Battista in … Cari amici miei … ATTO II | Foligno, Auditorium San Domenico Sabato 10 aprile

        Giobbe Covatta in Trenta | Narni, Teatro Comunale Mercoledì 14 aprile

        Maurizio Battista in … Cari amici miei … ATTO II | Cagli, Teatro Comunale Venerdì 16 aprile

        Paolo Cevoli in Recital 2010 | Panicale, Teatro Caporali Sabato 17 aprile

        Giuseppe Giacobazzi in Una vita da paura | Terni, Teatro Verdi Giovedì 29 aprile

        Marco “BAZ” Bazzoni in a BAZ live | Cagli, Teatro Comunale Venerdì 14 maggio

         `}
          <b>{`
          
          Luglio | Agosto 2009 XLIX Stagione Estiva Teatro Romano Gubbio`}</b>
          {`

          Marco Marzocca in Recital 2008 | Gubbio, Teatro Romano Mercoledì 19 agosto

          `}
          <b>{`
          
          Stagione 2011`}</b>
        
         {`

          I Mancio & Stigma + Daniela Ceva in Acronimo di E.M.O. | Foligno, Auditorium San Domenico 25 febbraio

          Massimo Bagnato in Quanti pensano | Narni, Teatro Comunale 5 marzo

          Massimo Bagnato in Quanti pensano | Foligno, Auditorium San Domenico 11 marzo

          Massimo Bagnato in Quanti pensano | Gubbio, Teatro Comunale 12 marzo

          David Riondino in Cavour mon amour | Panicale, Teatro Caporali 17 marzo

          Dario Vergassola in Sparla con me | Foligno, Auditorium San Domenico 9 aprile

          Dario Vergassola in Sparla con me | Gubbio, Teatro Comunale 10 aprile

          Paolo Hendel in Il tempo delle susine verdi | Narni, Teatro Comunale 15 aprile

          Michelle Hunziker in Mi scappa da ridere | Spoleto, Teatro Nuovo Gian Carlo Menotti 16 aprile

          Alessandro Bergonzoni in Urge | Spoleto, Teatro San Nicolò 6 maggio
          `}
          </div>
        </div>
      </div>
    </EntertainmentLayout>
  )
}

export default TeatroComico
