import React, { useContext, useEffect } from "react"
import Header from "../../components/header/header.js"
import Description from "../../components/description/description.js"
import { ThemeContext } from "../../context/ThemeContext"
import DuotoneImage from "../../components/duotoneImage/duotoneImage.js"
import Card from "../../components/card/Card.js"

import '../../assets/styles/pages/_entertainment.scss'

const CARDS = [

  {id: '1', to: '/entertainment/produzione/venditti', filename:'venditti', title:'Antonello Venditti', subtitle: 'Special Event Live'},
  {id: '2', to: '/entertainment/produzione/brignano', filename:'brignano', title:'Enrico Brignano', subtitle: 'Evolushow'},
  {id: '3', to: '/entertainment/produzione/gabbani', filename:'gabbani', title:'Francesco Gabbani', subtitle: 'Tour Magellano'},
  {id: '4', to: '/entertainment/produzione/allevi', filename:'allevi', title:'Giovanni Allevi', subtitle: 'Alien World Tour'},
  {id: '5', to: '/entertainment/produzione/ilovespoleto', filename:'ilovespoleto', title:'I Love Spoleto 2018'},
  {id: '6', to: '/entertainment/produzione/lifeinumbria', filename:'lifeinumbria', title:'  Life in Umbria...', subtitle: 'lo spettacolo prende vita'},
  {id: '7', to: '/entertainment/produzione/mannarino', filename:'mannarino', title:'Mannarino', subtitle: 'Tour Al Monte Live'},
  {id: '8', to: '/entertainment/produzione/teatro-comico', filename:'teatrocomico', title:'Teatro Comico'},
  {id: '9', to: '/entertainment/portfolio/dolcementeumbria', filename: 'dolcemente', title: 'Dolcemente Umbria', subtitle: 'Mercatino tipico dei dolci natalizi'},
  {id: '10', to: '/entertainment/portfolio/granatieri', filename: 'granatieri', title: 'Granatieri di Sardegna'},
  {id: '11', to: '/entertainment/portfolio/lifeingubbio', filename: 'lifeingubbio', title: 'Life in Gubbio', subtitle: 'lo spettacolo prende vita'},
  {id: '12', to: '/entertainment/portfolio/dolceumbria', filename: 'dolceumbria', title: 'La Dolce Umbria', subtitle: 'Mercatino dei dolci tipici umbri'},
  {id: '13', to: '/entertainment/portfolio/millemiglia', filename: 'millemiglia', title: 'La Mille Miglia',subtitle:`La corsa più bella del mondo`},
  {id: '14', to: '/entertainment/portfolio/spoletotipica', filename: 'spoletotipica', title: 'Spoleto Tipica', subtitle:`Mostra mercato prodotti enogastronomici`},
]


const Allestimenti = () => {
  const theme = useContext(ThemeContext)

  useEffect(() => {
    theme.setBackGroundColor("red")
    theme.setOriginPage({
      label: "Allestimenti",
      to: "/entertainment/allestimenti",
    })
    theme.setVisibleFooter(true)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header
        title="Allestimenti"
        bannerImage="entertainment/allestimenti-banner"
        metaDescription="ACME è stata scelta negli anni per l’esperienza maturata negli allestimenti di fiere, mostre, eventi, convegni."
      />
      <Description type="onTheLeft">
        <h2>
          ACME è stata scelta negli anni per l’esperienza maturata negli
          allestimenti di fiere, mostre, eventi, convegni.
        </h2>
        <p>
          Grazie al personale specializzato con il quale collabora è in grado di
          offrire soluzioni ad hoc pensate in base alle esigenze e richieste del
          cliente.
        </p>
      </Description>
      <div className="entertainment">
        <div className="columns">
          {CARDS.map(i => (
            <Card
              key={i.id}
              {...i}
              className="column is-one-third-desktop is-half-tablet is-full-mobile"
              imageComponent={DuotoneImage}
              imageType="negative"
            />
          ))}
        </div>
      </div>
    </>
  )
}

export default Allestimenti
