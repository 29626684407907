import React from "react"
import EntertainmentLayout from "../../../components/entertainmentLayout/entertainmentLayout.js"

const MilleMiglia = () => {
  return (
    <EntertainmentLayout
      category="Segreteria Organizzativa"
      title="La Mille Miglia &#10;“La corsa più bella del mondo” &#10;Enzo Ferrari"
      bannerImage="millemiglia.jpg"
      metaDescription="Mille Miglia un appuntamento che ti porta indietro nel tempo. Le auto d’epoca che hanno segnato un’era, caratterizzate da un design unico, proprio come la Ferrari."
      description="Mille Miglia è un’attitudine alla vita, un modo di essere dinamico,
      elegante e ricercato, in cui creatività e gusto italiano incontrano
      la sperimentazione e la contaminazione con moda, arte e design."
      contents={[{
        date:`2010 – 2011 – 2012
        Spoleto 
        `,
        text:`Promuovere la crescita di una cultura fatta di attenzione alle
        comunità, ai territori, alla mobilità rispettosa dell’ambiente. Ieri
        come oggi, Mille Miglia è portavoce del progresso tecnico, sociale
        ed umano.
         
         
        Mille Miglia è:
         
        Unicità dei territori esplorati, fisici e non / Esperienza in tema
        di auto, motori e mobilità / Passione per la bellezza, design, arte,
        territorio / Eccellenza Italiana nel mondo / Tradizione di un
        immenso patrimonio culturale / Innovazione piena di vitalità,
        impulso ed energia
         
         
        Mille Miglia è un marchio di lusso, simbolo della gara Italiana di
        auto d’epoca per eccellenza, che fa sognare il mondo con il suo
        design.
         
        L’Italia e il suo valore artistico, sociale e anche passionale: un
        contenitore di significati culturali in grado di attirare sogni e
        desideri.`,
        //image: "millemiglia.jpg"  
      }]} 
    />
  )
}

export default MilleMiglia
