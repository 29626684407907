import React, { useContext, useEffect } from "react"
import Header from "../../components/header/header.js"
import Description from "../../components/description/description.js"
import Software from "../../components/software/software.js"
import Tile from "../../components/tile/tile.js"
import DuotoneImage from "../../components/duotoneImage/duotoneImage.js"
import { ThemeContext } from "../../context/ThemeContext"
import "../../assets/styles/pages/_techSoft.scss"
const Technology = () => {
  const theme = useContext(ThemeContext)

  useEffect(() => {
    theme.setBackGroundColor("grey")
    theme.setOriginPage({
      label: "Technology & Software",
      to: "/tech-soft",
    })
    theme.setVisibleFooter(true)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header
        title="Technology &amp; Software"
        // bannerImage="chip"
        metaDescription="ACME grazie al suo team di sviluppo realizza software e APP partendo dai bisogni dei suoi clienti e puntando a migliorare l’user experience dell’utente."
      />
      <div className="section"/>
      <div id="index" className="container">
        <div className="tile">
            <Tile shape={"7"} offset={"1"} to={"/tech-soft/saas"} filename={"home/saas"} imageType="orange" >I nostri prodotti</Tile>
          <Tile shape={"3"} to={"/tech-soft/custom-software"} filename={"home/custom"} imageComponent={DuotoneImage} imageType="orange" >APP &amp; Software Personalizzati</Tile>
        </div>
        <div className="tile">
          <Tile shape={"3"} offset={"1"} to={"/tech-soft/it-service"} filename={"home/itservice"} imageComponent={DuotoneImage} imageType="orange" >Infrastrutture reti</Tile>
          <Tile shape={"7"} to={"/tech-soft/websites-creation"} filename={"home/websites"} imageComponent={DuotoneImage} imageType="orange" >Realizzazione Siti web</Tile>
        </div>
      </div>
      <div className="section"/>
      <Description type="onTheLeft">
        <h2>
          ACME grazie al suo team di sviluppo realizza software e APP partendo
          dai bisogni dei suoi clienti e puntando a migliorare l’user experience
          dell’utente.
        </h2>
        <p>
          ACME segue a 360 gradi la realizzazione dei suoi prodotti
          dall’ideazione allo sviluppo, passando per test e correzione bug, sino
          alla manutenzione e implementazione. ACME può realizzare una
          rete WiFi in grado di supportare le esigenze del cliente, siano esse
          temporanee, e collegate al singolo evento, o permanenti, e rivolte, ad
          attività commerciali private di qualsiasi natura o, a reti pubbliche -
          cittadine. ACME si serve di strumenti di rete certificati per
          l’installazione outdoor e indoor, che rispettano tutti gli standard
          europei e nazionali della legge sulle telecomunicazioni.
        </p>
      </Description>
      <Software />
    </>
  )
}

export default Technology
