import React from "react"
import { Link } from "gatsby"


const ListItem = ({ label, to, target, children }) => {


  return (
    <li>
      <Link to={to} target={target} className="acme-list__item" >
        {children||label}
      </Link>
    </li>
  )
}

export default ListItem
