import React, { useEffect, useState } from "react"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer";
import Jovanotti from "../../assets/img/customSoft/jova.png"
import JovaTitle from "../../assets/img/customSoft/jovalogo.png"
import Tiger from "../../assets/img/customSoft/tigre.png"

import "./style.scss";

const JovaLogo = () => {

  const controls = useAnimation();
  const [ref, inView] = useInView();
  const [height, setHeight] = useState('30rem');

  useEffect( () => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [])

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  function handleResize (e) {
    const title = document.getElementById('jovaTitle')
    const newHeigth = title.offsetHeight + title.height;
    if(newHeigth > 0)
      setHeight(newHeigth)
  };


  return (
    <>
      <div 
        id="jovaLogo"
        style={{height: height}}
      >
        <motion.div
          className="jovaLogo__item"
          animate={controls}
          initial="hidden"
          variants={{
            hidden: { y: '200%', x: '-50%', opacity: 0 },
            visible: { y: '55%', x: '40%', scale: 1.1, opacity: 1, transition: { delay: 0.5 } }
          }}
        >
          <img id="tiger" src={Tiger} alt="Tigre"/>
        </motion.div>
        <motion.div
          className="jovaLogo__item"
          animate={controls}
          initial="hidden"
          variants={{
            hidden: { y: '200%', x: '-50%', opacity: 0 },
            visible: { y: '10%', x: '25%', scale: 1.1, opacity: 1, transition: { delay: 0.2 }},
          }}
        >
          <img id="jovanotti" src={Jovanotti} alt="Jovanotti"/>
        </motion.div>
        <motion.div
          className="jovaLogo__item"
          animate={controls}
          initial="hidden"
          variants={{
            hidden: { y: '75%', x: '0%', scale: 0 },
            visible: { y: '75%', x: '10%', scale: 1.1 },
          }}
          onAnimationComplete={() => handleResize()}
        >
          <img 
            ref={ref} 
            id="jovaTitle" 
            src={JovaTitle} 
            alt="Jova Beach Party Logo"/>
        </motion.div>
      </div>
    </>    
  )
}
  
export default JovaLogo
  