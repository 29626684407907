import React from "react"
import TechSoftLayout from "../../../components/techSoftLayout/techSoftLayout.js"
import {PROJECTS} from "../saas";

const ConnectAtYou = () => {


  return (
    <TechSoftLayout
      title="Connect@You"
      //bannerImage="ragazzo-al-portatile-connessione-wifi"
      descriptionType="onTheLeft"
      metaDescription="Connect@You moderno servizio di hotspot per offrire ai clienti una connessione WiFi gratuita, facile e sicura. Compliant al nuovo GDPR Regolamento UE 2016/679."
      PROJECTS={PROJECTS}
      description={`Connect@You è un moderno servizio di hotspot grazie al quale potrai
      offrire ai tuoi clienti una connessione WiFi gratuita, facile e
      sicura, garantendogli la possibilità di essere in rete con pochi
      click, mettendoti al riparo dalla normativa vigente. Connect@You è
      infatti compliant al nuovo GDPR Regolamento (UE) 2016/679.`}
      contents={[{
        text: `Connect@You è un prezioso strumento di marketing grazie al quale
        potrai raccogliere i dati profilati degli utenti che si connettono
        alla tua rete WiFi, in modo da attivare campagne e
        strategie promozionali mirate per la tua attività.
        È un software completamente personalizzabile, sia a livello di
        contenuti che grafico. Offre diverse modalità di autenticazione,
        alcune tra le più comuni, facili e fruibili da tutti.`,
          image: "https://static.acmeproduzioni.it/connectatyou/logo-white-300.webp"
        },
        {
        text: `Per ogni modalità di autenticazione è previsto un diverso redirect, che
        ti consentirà di comunicare con i follower della piattaforma social
        scelta, così potrai promuovere la tua attività e far crescere il
        tuo business. Il gateway in configurazione standard è Plug&Play e di facile
        installazione.

        L'accesso al BackOffice è possibile con qualunque dispositivo possa 
        collegarsi ad internet con un browser (consigliamo Chrome) e 
        con estrema facilità puoi avere il controllo della tua WiFi.`,
        image: "cau-screen",
        linkText:"Vai al sito",
        to:"https://connectatyou.it/"
        },
      ]}
      // firstImage={"cau/phone1.png"}
      // secondImage={"cau/phone2.png"}
    />
  )
}

export default ConnectAtYou
