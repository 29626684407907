import React, { useContext, useEffect } from "react";
import Post from "../components/post/post";
import Header from "../components/header/header.js"
import { ThemeContext } from "../context/ThemeContext"

import { StaticQuery, graphql } from 'gatsby';

const query = graphql`
  query {
    allStrapiAcmeWebsiteBlogs(sort: {order: DESC, fields: published_at}) {
      edges {
        node {
          strapiId
          title
          slug
          published_at(formatString: "DD/MM/YYYY")
          body
          image {
            publicURL
          }
        }
      }
    }
  }
`;


const Blog = ({ data }) => {
  const theme = useContext(ThemeContext)

  useEffect(() => {
    theme.setBackGroundColor("white")
    theme.setOriginPage({ label: "Home", to: "/" })
    theme.setVisibleFooter(true)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
     <Header
        title="Blog &amp; News"
      />
      <StaticQuery
        query={query}
        render={data => ( 
        data.allStrapiAcmeWebsiteBlogs.edges.map((post, index) =>{ 
           const { title, published_at, body, slug, image } = post.node;
         
           return ( 
        <Post
          title={title}
          index={index}
          author={'Diego'}
          date={published_at}
          description={body}
          key={slug}
          path={slug}
          image={image.publicURL}
        /> )}))}
    />
    
    </>
  )}

export default Blog;