import React from "react"
import TechSoftLayout from "../../../components/techSoftLayout/techSoftLayout.js"

const GlutenFreeFest = () => {
  return (
    <TechSoftLayout
      title="Gluten Free Fest"
      descriptionType="customAndWifi"
      metaDescription="In occasione del Gluten Free Festival abbiamo realizzato infrastruttura WiFi e garantito servizi di autenticazione clienti, connessione."
      contents={[{
        date:`Committente: Sedicieventi - Div. Gioform Srl
        Maggio - Giugno 2015
        Perugia, Percorso Verde Pian di Massiano
        
        `,
        text: `                
        A Perugia va in scena Gluten Free Fest il Festival del Senza Glutine
        rivolto a un pubblico di tutte le età.
        
        L’obiettivo principale è quello di accrescere l’attenzione sul tema
        della celiachia.
        
        Durante i 5 giorni di festa si è parlato di senza glutine attraverso
        iniziative legate al mondo dello sport e del benessere, senza
        dimenticare gli appuntamenti con degustazioni guidate, lezioni di
        cucina gluten free, seminari, laboratori e attività per bambini.
        Ovviamente, non sono mancate le aree ristoro e finger food, per
        acquistare e mangiare prodotti e bevande senza glutine.`,
        image: "gluten.png"
        },
        {
        text: {
            partOne: `Esigenze del cliente

            • Copertura WiFi delle aree evento
            • Rendere estremamente semplice e veloce la procedura di autenticazione per gli ospiti`,
            partTwo: `Le nostre soluzioni

            • Realizzazione infrastruttura rete WiFi per stand espositori, area spettacolo e food
            • Fornitura connettività dedicata
            • Captive portal Connect@You per l’autenticazione del pubblico e degli espositori
            • Servizio di assistenza`
          }
        }
      ]}     
    />
  )
}

export default GlutenFreeFest
