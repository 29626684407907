import React, { useContext, useEffect } from "react"
import Header from "../../components/header/header.js"
import Description from "../../components/description/description.js"
import { ThemeContext } from "../../context/ThemeContext"
import Tile from "../../components/tile/tile.js"
import DuotoneImage from "../../components/duotoneImage/duotoneImage.js"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay } from 'swiper';
import "swiper/swiper.scss"
import Card from "../../components/card/Card.js"

import '../../assets/styles/pages/_entertainment.scss'

const CARDS = [
  {id: '1', to: '/entertainment/produzione/alice', filename:'alice', title:'Alice nel Paese delle Meraviglie', subtitle: 'Il Musical'},
  {id: '2', to: '/entertainment/produzione/venditti', filename:'venditti', title:'Antonello Venditti', subtitle: 'Special Event Live'},
  {id: '3', to: '/entertainment/produzione/brignano', filename:'brignano', title:'Enrico Brignano', subtitle: 'Evolushow'},
  {id: '4', to: '/entertainment/produzione/cochierenato', filename:'cochirenato', title:'Cochi e Renato', subtitle: 'Finchè c\'è la salute'},
  {id: '5', to: '/entertainment/produzione/gabbani', filename:'gabbani', title:'Francesco Gabbani', subtitle: 'Tour Magellano'},
  {id: '6', to: '/entertainment/produzione/allevi', filename:'allevi', title:'Giovanni Allevi', subtitle: 'Alien World Tour'},
  {id: '7', to: '/entertainment/produzione/ilovespoleto', filename:'ilovespoleto', title:'I Love Spoleto 2018'},
  {id: '8', to: '/entertainment/produzione/lifeinumbria', filename:'lifeinumbria', title:'  Life in Umbria...', subtitle: 'lo spettacolo prende vita'},
  {id: '9', to: '/entertainment/produzione/mannarino', filename:'mannarino', title:'Mannarino', subtitle: 'Tour Al Monte Live'},
  {id: '10', to: '/entertainment/produzione/teatro-comico', filename:'teatrocomico', title:'Teatro Comico'},
  {id: '11', to: '/entertainment/produzione/mammucari', filename:'mammucari', title:'Teo Mammucari', subtitle:'Live'},
  {id: '12', to: '/entertainment/produzione/trepianogen', filename:'3pianogen', title:'Luis Bacalov, Rita Marcotulli e Alberto Pizzo', subtitle:'3 Piano Generations' },
]

const SLIDES = [
  {id: '1', to: '/entertainment/portfolio/annomenottiano', filename: 'anno-menottiano', title: 'Anno Menottiano', subtitle:`Centenario Maestro Gian Carlo Menotti`},
  {id: '2', to: '/entertainment/portfolio/dolcementeumbria', filename: 'dolcemente', title: 'Dolcemente Umbria', subtitle: 'Mercatino tipico dei dolci natalizi'},
  {id: '3', to: '/entertainment/portfolio/granatieri', filename: 'granatieri', title: 'Granatieri di Sardegna'},
  {id: '4', to: '/entertainment/portfolio/lifeingubbio', filename: 'lifeingubbio', title: 'Life in Gubbio...', subtitle: 'lo spettacolo prende vita'},
  {id: '5', to: '/entertainment/portfolio/dolceumbria', filename: 'dolceumbria', title: 'La Dolce Umbria', subtitle: 'Mercatino dei dolci tipici umbri'},
  {id: '6', to: '/entertainment/portfolio/millemiglia', filename: 'millemiglia', title: 'La Mille Miglia',subtitle:`La corsa più bella del mondo`},
  {id: '7', to: '/entertainment/portfolio/spoletotipica', filename: 'spoletotipica', title: 'Spoleto Tipica',subtitle:`Mostra mercato prodotti enogastronomici`},
]



const Entertainment = () => {
  const theme = useContext(ThemeContext)

  useEffect(() => {
    theme.setBackGroundColor("red")
    theme.setOriginPage({label:"Entertainment", to: "/entertainment/"})
    theme.setVisibleFooter(true)
  },[]) // eslint-disable-line react-hooks/exhaustive-deps

  SwiperCore.use([Autoplay])

  return (
    <>
      <Header
        title="Entertainment"
        // bannerImage="entertainment/entertainment-banner"
        metaDescription="ACME nasce nel 2008 occupandosi di eventi, intrattenimento e spettacolo."
      />
      <div className="section"/>
      <div id="index" className="container">
        <div className="tile">
          <Tile shape={"3"} to={"/entertainment/produzione"} filename={"home/produzione"} imageComponent={DuotoneImage} imageType="red" >Produzione</Tile>
          <Tile shape={"6"} to={"/entertainment/artistica"} filename={"home/artistica"} imageComponent={DuotoneImage} imageType="red" >Direzione Artistica</Tile>
          <Tile shape={"3"} to={"/entertainment/tecnica"} filename={"home/tecnica"} imageComponent={DuotoneImage} imageType="red" >Direzione Tecnica</Tile>
        </div>
        <div className="tile">
          <Tile shape={"3"} to={"/entertainment/segreteria"} filename={"home/segreteria"} imageComponent={DuotoneImage} imageType="red" >Segreteria Organizzativa </Tile>
          <Tile shape={"3"} to={"/entertainment/allestimenti"} filename={"home/allestimenti"} imageComponent={DuotoneImage} imageType="red" >Allestimenti</Tile>
          <Tile shape={"6"} to={"/cinemaspottv"} filename={"home/cinema"} imageComponent={DuotoneImage} imageType="sepia" >Cinema, Spot &amp; TV</Tile>
        </div>        
      </div>
      <div className="section"/>
      <Description type="onTheLeft">
        <h2>
          ACME nasce nel 2008 occupandosi di eventi, intrattenimento e
          spettacolo.
        </h2>
        <p>
          Oggi nel portfolio dei suoi lavori può vantare relazioni con
          differenti realtà artistiche, realizzazioni che spaziano
          dall’organizzazione di eventi musicali e teatrali, alla logistica del
          personale, dalla gestione di strutture tecniche specialistiche per lo
          spettacolo, sino alle consulenze artistiche.
        </p>
      </Description>
      <div className="entertainment">
        <h2>Produzioni ACME &copy;</h2>
        <div className="columns">
          {CARDS.map(i => (
            <Card
              key={i.id}
              {...i}
              className="column is-one-third-desktop is-half-tablet is-full-mobile"
              imageComponent={DuotoneImage}
              imageType="negative"
            />
          ))}
        </div>
      </div>
      <div className="portfolio">
        <h2>Portfolio</h2>
        <div id="entertainment-slide" className="tile">
          <Swiper spaceBetween={50} slidesPerView={"auto"} grabCursor={true} loop= {true} autoplay={{ delay: 2000, disableOnInteraction: false }}>
            {SLIDES.map(i => (
              <SwiperSlide key={i.id} virtualIndex={i.id}>
                <Card {...i} imageComponent={DuotoneImage} imageType="red"/>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  )
}

export default Entertainment
