import React from "react"
import TechSoftLayout from "../../../components/techSoftLayout/techSoftLayout.js"
//import JovaLogo from "../../../components/jovaLogo/jovaLogo.js"
import Image from "../../../components/image/image.js"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay } from "swiper"

const JovaBeachParty2022 = () => {
  SwiperCore.use([Autoplay])

  const FIRSTCAROUSEL = [
    {
      id: 1,
      filename: "customSoft/presentazione-jova-beach-party-2022-part-1.jpg",
      alt: "Presentazione Jova Beach Party 2022 Part 1",
    },
    {
      id: 2,
      filename: "customSoft/banner-jovanotti.jpg",
      alt: "Banner Jovanotti",
    },
    {
      id: 3,
      filename: "customSoft/presentazione-jova-beach-party-2022-part-2.jpg",
      alt: "Presentazione Jova Beach Party 2022 Part 2",
    },
    {
      id: 4,
      filename: "customSoft/jovanotti-e-giorgio-parisi",
      alt: "Jovanotti e Giorgio Parisi Premio Nobel",
    },
    {
      id: 5,
      filename: "customSoft/jova-beach-party-2022-conferenza",
      alt: "Jova Beach Party 2022 Conferenza",
    },
  ]

  const SECONDCAROUSEL = [
    {
      id: 1,
      filename: "customSoft/presentazione-APP-jova-beach-party-2022-part-1.jpg",
      alt: "Presentazione APP Jova Beach Party 2022 Part 1",
    },
    {
      id: 2,
      filename: "customSoft/jova-beach-party-2022-screenshots-part-1.png",
      alt: "Jova Beach Party Screenshots APP Part 1",
    },
    {
      id: 3,
      filename: "customSoft/presentazione-APP-jova-beach-party-2022-part-2.jpg",
      alt: "Presentazione APP Jova Beach Party 2022 Part 2",
    },
    {
      id: 4,
      filename: "customSoft/jova-beach-party-2022-screenshots-part-2.png",
      alt: "Jova Beach Party Screenshots APP Part 2",
    },
  ]

  return (
    <TechSoftLayout
      title="Jova Beach Party Tour 2022"
      descriptionType="customAndWifi"
      description={`Per Trident Music srl abbiamo sviluppato la nuova APP ufficiale del tour Jova Beach Party 2022.`}
    >
      <div className="section" />
      <div className="columns custom m-1">
        <div className="column is-6-tablet is-12-mobile">
          <p>
            <b>
              Abbiamo deciso di riscrivere interamente la versione 2.0
              utilizzando tutti i tool di ultima generazione a disposizione. Si
              ripete l’esperienza di collaborazione tra il nostro Staff e la
              parte creativa dello Staff di Lorenzo, per la realizzazione di uno
              strumento di comunicazione all’avanguardia, con i più elevati
              standard di velocità di consultazione per la parte del MEGAMIX,
              delle JOVA Session &#40;disponibili da Gennaio 2022&#41;. Il primo
              ed unico performer che ha inventato un suo spazio e un nuovo
              format di show!
            </b>
          </p>
          <br />
          <p>
            Esigenze del cliente
            <br />
            <br />• Realizzare una APP nativa dedicata al tour con diverse
            funzionalità di informazione e comunicazione per il primo tour
            italiano sulle spiagge di alcune tra le principali location
            italiane.
            <br />• Inserimento all’interno della APP di podcast creati
            dall’artista, di una radio dedicata e di eventuali sessioni live del
            tour
            <br />• Contenuti esclusivi disponibili solo per utenti autenticati
            <br />• Inserimento di una playlist disponibile solo all’interno
            della APP contenente la colonna sonora del format “Non voglio
            cambiare pianeta” trasmesso dalla Rai
            <br />• Comunicazione e vendita dei biglietti per ogni singola data
          </p>
          <p>
            <br />
            <br />
            Le nostre soluzioni
            <br />
            <br />• Realizzazione ed ideazione della struttura della APP nativa
            per smartphone e tablet con sistema operativo iOS e Android
            <br />• Gestione delle Jova Session, i podcast registrati da Lorenzo
            e pubblicati sulla APP
            <br />• Gestione delle session live registrate durante il tour e
            pubblicate sulla APP
            <br />• Servizio di assistenza dedicata per tutta la durata del tour
            <br />• Player audio disponibile anche con APP in background e
            navigabile anche tramite dispositivi bluetooth connessi
            <br />• Registrazione utente per accesso ai contenuti esclusivi in
            APP tramite SMS, Email, Facebook, Google e Apple
          </p>
        </div>
        <div className="column is-6-tablet is-12-mobile">
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            grabCursor={true}
            loop={true}
            autoplay={{ delay: 2500, disableOnInteraction: false }}
          >
            {FIRSTCAROUSEL.map(i => (
              <SwiperSlide key={i.id}>
                <Image filename={i.filename} alt={i.alt} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="section" />
      <div className="columns custom m-1 invert">
        <div className="column is-6-tablet is-12-mobile">
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            grabCursor={true}
            loop={true}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
          >
            {SECONDCAROUSEL.map(i => (
              <SwiperSlide key={i.id}>
                <Image filename={i.filename} alt={i.alt} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="column is-6-tablet is-12-mobile">
          <p>            
            La APP è già disponibile su Apple Store e Google Play, costantemente aggiornata secondo esigenze del cliente.
          </p>
          <div className="section" />
          <div className="columns goToApp">
            <div className="column">
              <a
                rel="noreferrer"
                href="https://acmeproduzioni.page.link/jovabeach"
                target="_blank"
              >
                <button className="goTo">Vai all'APP</button>
              </a>
            </div>
            <div className="column">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://acmeproduzioni.page.link/jovabeach"
                style={{ width: "590px", maxWidth: "70%" }}
              >
                 <Image filename="jova-2022.png" alt="Jova Beach Party Tour 2022"/>
              </a>
            </div>
          </div>
          <div className="section"/>
        </div>
      </div>
    </TechSoftLayout>
  )
}

export default JovaBeachParty2022
