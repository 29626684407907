import React, { useContext, useEffect } from "react"
import Header from "../components/header/header.js"
import Description from "../components/description/description.js"
import { ThemeContext } from "../context/ThemeContext"
import { Swiper, SwiperSlide } from "swiper/react"
import {List, ListItem} from "../components/list"
import "swiper/swiper.scss"
import Card from "../components/card/Card.js"
import SwiperCore, { Autoplay } from 'swiper';


const CinemaSpotTV = () => {
  const theme = useContext(ThemeContext)

  const MOVIES = [
    {
      title: "Domina",
      description: "TV Series | 2nd Unit - 8 episodes | post-production",
      to: "https://www.imdb.com/title/tt11694186/?ref_=nm_flmg_snd_1",
      filename: "",
      year:"2021"
    },
    {
      title: "Christmas In Rome",
      description: "TV Movie | sound mixer: Rome",
      to: "https://www.imdb.com/title/tt10344956/?ref_=nm_flmg_snd_2",
      filename: "christmas-in-rome.jpg",
      year:"2019"
    },
    {
      title: "Attraverso i miei occhi",
      description: "Sound mixer",
      to: "https://www.imdb.com/title/tt1478839/?ref_=nm_flmg_snd_3",
      filename: "attraverso-i-miei-occhi.jpg",
      year:"2019"
    },
    {
      title: "Un amore da copertina",
      description: "TV Movie sound mixer: Rome",
      to: "https://www.imdb.com/title/tt7049178/?ref_=nm_flmg_snd_4",
      filename: "rome-in-love.jpg",
      year:"2019"
    },
    {
      title: "Una festa esagerata",
      description: "Sound mixer",
      to: "https://www.imdb.com/title/tt7730492/?ref_=nm_flmg_snd_5",
      filename: "una-festa-esagerata.jpg",
      year:"2018"
    },
    {
      title: "Caccia al tesoro",
      description: "Sound mixer",
      to: "https://www.imdb.com/title/tt6918790/?ref_=nm_flmg_snd_6",
      filename: "caccia-al-tesoro.jpg",
      year:"2017"
    },
    {
      title: "Non si ruba a casa dei ladri",
      description: "Sound mixer",
      to: "https://www.imdb.com/title/tt6218374/?ref_=nm_flmg_snd_7",
      filename: "non-si-ruba-a-casa-dei-ladri.jpg",
      year:"2016"
    },
    {
      title: "Miami Beach",
      description: "Sound mixer",
      to: "https://www.imdb.com/title/tt5663624/?ref_=nm_flmg_snd_8",
      filename: "miami-beach.jpg",
      year:"2016"
    },
    {
      title: "Allacciate le cinture",
      description: "Sound mixer",
      to: "https://www.imdb.com/title/tt3425034/?ref_=nm_flmg_snd_9",
      filename: "allacciate-le-cinture.jpg",
      year:"2014"
    },
    {
      title: "Le ultime 56 ore",
      description: "Sound mixer",
      to: "https://www.imdb.com/title/tt1440231/?ref_=nm_flmg_snd_10",
      filename: "le-ultime-56-ore.jpg",
      year:"2010"
    },
    {
      title: "Christopher Roth",
      description: "Sound mixer",
      to: "https://www.imdb.com/title/tt1484942/?ref_=nm_flmg_snd_11",
      filename: "christopher-roth.jpg",
      year:"2010"
    },
    {
      title: "La terza verità",
      description: "TV Movie sound mixer",
      to: "https://www.imdb.com/title/tt1034426/?ref_=nm_flmg_snd_12",
      filename: "",
      year:"2007"
    },
    {
      title: "Come le formiche",
      description: "Sound mixer",
      to: "https://www.imdb.com/title/tt0863117/?ref_=nm_flmg_snd_13",
      filename: "",
      year:"2007"
    },
    {
      title: "Sweet Sweet Marja",
      description: "Additional sound designer / sound mixer",
      to: "https://www.imdb.com/title/tt0888527/?ref_=nm_flmg_snd_14",
      filename: "",
      year:"2007"
    },
    {
      title: "Troppo belli",
      description: "Sound mixer",
      to: "https://www.imdb.com/title/tt0469849/?ref_=nm_flmg_snd_15",
      filename: "",
      year:"2005"
    },
    {
      title: "Concorso di colpa",
      description: "Sound mixer",
      to: "https://www.imdb.com/title/tt0456902/?ref_=nm_flmg_snd_16",
      filename: "",
      year:"2004"
    },
    {
      title: "Vento di ponente",
      description: "TV Series sound mixer - 2002",
      to: "https://www.imdb.com/title/tt0307850/?ref_=nm_flmg_snd_17",
      filename: "",
      year:"2002"
    },
    {
      title: "Sei forte, Maestro",
      description: "TV Series boom operator - 2000",
      to: "https://www.imdb.com/title/tt0257320/?ref_=nm_flmg_snd_18",
      filename: "",
      year:"2000"
    },
    {
      title: "Una donna per amico",
      description: "TV Series boom operator - 2001",
      to: "https://www.imdb.com/title/tt0168330/?ref_=nm_flmg_snd_19",
      filename: "",
      year:"1998"
    },
  ]

  useEffect(() => {
    theme.setBackGroundColor("loafer")
    theme.setVisibleFooter(true)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  SwiperCore.use([Autoplay])

  return (
    <>
      <Header
        title="Cinema, Spot &amp; Tv"
        bannerImage={[
          {id: 1, filename:"vasca-domina", alt:"TV Series Domina - Vasca"},          
          {id: 2, filename:"colonne-domina", alt:"TV Series Domina - Colonne"}          
        ]}
        metaDescription="ACME collabora con società di produzioni cinematografiche e televisive nazionali ed internazionali, integrandone le strutture a vari livelli."
      />
      <Description type="onTheLeft">
        <h2>
          ACME collabora con le principali società di produzione
          cinematografiche e televisive nazionali ed internazionali,
          integrandone le strutture a vari livelli, con professionalità.
        </h2>
        <p>
          Offre il servizio di noleggio materiale audio per la presa diretta,
          personale tecnico qualificato quale fonici e microfonisti, servizi
          tecnici, location manager e assistenza alla produzione.
        </p>
      </Description>
      <div className="cinemaspottv  mb-5">
        <Swiper slidesPerView={"auto"} grabCursor={false} loop= {true} autoplay={{ delay: 2500, disableOnInteraction: false }}>
          {MOVIES.map(
            movie =>
              movie.filename && (
                <SwiperSlide key={movie.title}>
                  <div className="pb-2">
                    <b>
                      {movie.title}&nbsp;({movie.year})
                    </b>
                    <br />
                    {movie.description} | <a target="_blank" rel="noreferrer" href="https://www.imdb.com/name/nm2674112/"><u>Mirko Pantalla</u><br/></a> Noleggio materiale audio presa diretta | ACME Produzioni srl
                  </div>
                  <Card
                    shape={"square playbill"}
                    to={movie.to}
                    alt={movie.title}
                    target="_blank"
                    filename={movie.filename}
                  />
                </SwiperSlide>
              )
          )}
        </Swiper>
      </div>
      <div className="allMovies">
        <h2>All movies</h2>
      </div>
      <List>
        {MOVIES.map(movie => (
          <ListItem to={movie.to} target="_blank" key={movie.title}>
            <b>{movie.title}</b>&nbsp;{movie.description}&nbsp;({movie.year}) | <a target="_blank" rel="noreferrer" href="https://www.imdb.com/name/nm2674112/"><u>Mirko Pantalla</u><br/></a> Noleggio materiale audio presa diretta | ACME Produzioni srl
          </ListItem>
        ))}
      </List>
    </>
  )
}

export default CinemaSpotTV
