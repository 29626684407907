import React, { useContext, useEffect } from "react"
import Header from "../../components/header/header.js"
//import Description from "../../components/description/description.js"
import { ThemeContext } from "../../context/ThemeContext"
import JovaLogo from "../../components/jovaLogo/jovaLogo.js"
import Image from "../../components/image/image.js"
import { Link } from "gatsby"

const CustomSoftware = () => {
  const theme = useContext(ThemeContext)

  useEffect(() => {
    theme.setBackGroundColor("grey")
    theme.setOriginPage({
      label: "APP & Software Personalizzati",
      to: "/tech-soft/custom-software",
    })
    theme.setVisibleFooter(true)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header
        title="APP &amp; Software Personalizzati"
        //bannerImage="chip"
        metaDescription="Hai bisogno di un APP o di un software? ACME realizza software e APP, estremamente performanti, cuciti sulle esigenze del singolo cliente."
      />
      <div className="section" id="ACME-CustomSoft"/>
      {/* <Description type="onTheLeft">
        <h2>Hai bisogno di un APP o di un software?</h2>
        <p>
          Basta spingersi oltre! ACME nel suo portfolio vanta la realizzazione
          di software e APP, estremamente performanti, cuciti sulle esigenze del
          singolo cliente. ACME col suo team di sviluppo segue a 360 gradi la
          realizzazione dei prodotti, dall’ideazione allo sviluppo, passando per
          test e correzione bug, sino alla manutenzione e implementazione. Il
          risultato? Software e APP in grado di migliorare l’user experience
          dell’utente.
        </p>
      </Description> */}
      <div className="customSoft">
        <div className="columns">         
          <div className="column">
            <Link to="/tech-soft/custom/jovabeachparty2022">
              <div className="customLogo">
                <Image filename="jova-2022.png" />
              </div>
            </Link>
          </div>
          <div className="column">
            <Link to="/tech-soft/custom/ptn">
              <div className="customLogo">
                <Image filename="ptn.png" />
              </div>
            </Link>
          </div>         
        </div>        
        <div className="columns">
          <div className="column">
            <Link to="/tech-soft/custom/jovabeachparty">
              <div className="customLogo">
                <JovaLogo />
              </div>
            </Link>
          </div>          
          <div className="column">
            <Link to="/tech-soft/custom/riccionechristmas">
              <div className="customLogo">
                <Image filename="riccione-christmas-village.png" />
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="section" />
    </>
  )
}

export default CustomSoftware
