import React, { useContext } from "react"
import { Link } from "gatsby"
import { ThemeContext } from "../../context/ThemeContext"
import LogoAGCOM from "../../assets/img/logo-agcom.webp"
import LogoMinSviluppo from "../../assets/img/logo-min-sviluppo.webp"
import LogoUmbria from "../../assets/img/logo-umbria.jpg"
import LogoRepItaliana from "../../assets/img/logo-repubblica-italiana.jpg"
import LogoEU from "../../assets/img/logo-eu.jpg"
import LogoPORFESR from "../../assets/img/logo-porfesr.jpg"
import "./style.scss"

const Footer = () => {
  const theme = useContext(ThemeContext)

  return (
    theme.visibleFooter && (
      <footer>
        <div className={`footerTextColor ${theme.backGroundColor}`}>
          <div className="inner-footer">
            <div className="copyright">
              <p>&nbsp; &copy; {theme.currentYear} </p>
            </div>
            <h3 className="column is-offset-1-tablet ">ACME Produzioni Srl</h3>
            <div className="columns is-multiline m-0">
              <div className="column is-offset-1-tablet is-2-tablet contacts">
                <div className="address">
                  <p>Sede Legale<br />
                    Circonvallazione Clodia, 163/171<br />
                    00195 Roma (RM)<br /><br />
                  </p>
                  <p>Sede Operativa<br />
                    Via del Tessinello, 3<br />
                    06049 Spoleto (PG)<br />
                  </p>
                </div>
              </div>
              <div className="column is-2-tablet pages">
                <p>
                  Tel.&nbsp;<a href="tel:+390621128365">+39 06 21128365</a>
                </p>
                <p>
                  Email&nbsp;
                  <a href="mailto:info@acmeproduzioni.it">
                    info@acmeproduzioni.it
                  </a>
                </p>
                <p>P.Iva 10245151005</p>
                <p>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="http://docs.acmeproduzioni.it/acme-website/privacy-policy-it.html"
                  >
                    Privacy Policy
                  </a>
                  &nbsp;-&nbsp;
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="http://docs.acmeproduzioni.it/acme-website/cookie-policy-it.html"
                  >
                    Cookie Policy
                  </a>
                  <br/>
                  <button
                      className="cookiePreferences"
                      data-cc="show-preferencesModal"
                  >
                    Gestisci preferenze Cookie
                  </button>
                </p>
              </div>
              <div className="column is-2-tablet pages">
                <p>
                  <Link to="/entertainment/">Entertainment</Link>
                </p>
                <p>
                  <Link to="/tech-soft/">Technology &amp; Software</Link>
                </p>
                <p>
                  <Link to="/cinemaspottv">Cinema, Spot &amp; TV</Link>
                </p>
                <p>
                  <Link to="/ourworks">Lavori</Link>
                </p>
                <p>
                  <Link to="/blog">Blog &amp; News</Link>
                </p>
                <p>
                  <Link to="/contacts">Contatti</Link>
                </p>
              </div>
              <div className="column is-1-tablet social">
                <p>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/acmeproduzioni/"
                  >
                    Facebook
                  </a>
                </p>
                <p>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/acmeproduzioni_official/"
                  >
                    Instagram
                  </a>
                </p>
                <p>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/acme-produzioni/"
                  >
                    LinkedIn
                  </a>
                </p>
              </div>
              <div className="column ministry is-offset-2-tablet is-offset-0-desktop is-8-tablet is-3-desktop">
                <div className="columns">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.mise.gov.it/index.php/it/"
                    className="column is-full-mobile"
                  >
                    <img
                      src={LogoMinSviluppo}
                      alt="Logo Ministero Dello Sviluppo"
                    />

                    <p>
                      <br />
                      ACME Produzioni Srl è autorizzata dal Ministero dello
                      Sviluppo Economico Dipartimento Comunicazioni a fornire il
                      servizio di Internet Service Provider (ISP), con
                      iscrizione del 02.11.2016
                      <br />
                    </p>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.agcom.it/"
                    className="column is-full-mobile"
                  >
                    <img src={LogoAGCOM} alt="Logo AGCOM" />

                    <p>
                      <br />
                      ACME Produzioni Srl è iscritta al Registro Pubblico degli
                      Operatori di Comunicazione, stabilito dall’AGCOM (Autorità
                      per le Garanzie nelle Comunicazioni) con numero ROC: 27907
                      del 14.03.2017
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div className="POR_FESR">
              <div className="columns m-0">
                <div className="column is-three-fifths is-offset-one-fifth">
                  <img src={LogoEU} alt="Logo EU" />
                  &nbsp;
                  <img src={LogoUmbria} alt="Logo Regionne Umbria" />
                  &nbsp;
                  <img src={LogoRepItaliana} alt="Logo Repubblica Italiana" />
                  &nbsp;
                  <img src={LogoPORFESR} alt="Logo PORFESR" />
                  &nbsp;
                </div>
              </div>
              <div className="columns m-0">
                <div className="column is-three-fifths is-offset-one-fifth">
                  <p>
                    “Sviluppo piattaforme web corporate e sistema ERP integrato
                    per la gestione autonoma dei servizi”
                    <br />
                    “Development of a corporate web platforms and integrated ERP
                    system for the autonomous management of services”
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  )
}

export default Footer
