import React from "react"
import TechSoftLayout from "../../../components/techSoftLayout/techSoftLayout.js"

const MotorDays = () => {
  return (
    <TechSoftLayout
      title="International &#10;Motor Days"
      descriptionType="customAndWifi"
      metaDescription="Per International Motor Days abbiamo realizzato infrastruttura WiFi e garantito servizi di autenticazione clienti, connessione."
      contents={[{
        date:`Committente: ZTL Racing Events Srl
        Anno: Maggio 2018 – Giugno 2019
        Luogo: Porto Sant’Elpidio
        
        `,
        text: `
        International Motor Days: tre giorni di off-road, stunt-show e
        divertimento. Evento dedicato all’avventura off-road, con 65.000 mq
        dedicati a spettacoli stuntman ed auto, percorsi attrezzati
        off-road, fiera dei motori, ospiti, concerti e dj set. Un'esperienza
        di tre giorni imperdibile per gli appassionati ma anche per chi
        vuole trascorrere un emozionante weekend con amici e familiari.`,
        image: "motor.png"
        },
        {
        text: {
            partOne: `Esigenze del cliente

            • Copertura WiFi dell’area dell’evento
            • Rendere estremamente semplice e veloce la procedura di autenticazione per gli ospiti`,
            partTwo: `Le nostre soluzioni
            
            • Realizzazione infrastruttura rete WiFi per stand espositori, area spettacolo e food
            • Fornitura connettività dedicata
            • Captive portal Connect@You per l’autenticazione del pubblico e degli espositori
            • Servizio di assistenza`
          }
        }
      ]}     
    />
  )
}

export default MotorDays
