import React from "react"
import EntertainmentLayout from "../../../components/entertainmentLayout/entertainmentLayout.js"

const Gabbani = () => {
  return (
    <EntertainmentLayout
      category="Segreteria Organizzativa"
      title="Francesco Gabbani &#10;Tour Magellano"
      bannerImage="francesco-gabbani-banner.jpg"
      metaDescription="Durante il concerto Francesco Gabbani, inserito nel programma dell’evento “Spoleto d’Estate 2017”, tra i brani ha eseguito anche Occidentali’s Karma."
      description="Il concerto è inserito all’interno del programma degli Eventi estivi del Comune di Spoleto “Spoleto d’Estate 2017”."
      contents={[{
        date:`Agosto 2017
        Spoleto, Piazza Duomo        
        `,
        text:`Reduce dalla grande vittoria di San Remo e dall’enorme visibilità 
        avuta grazie all’Eurovision Song Contest, Francesco Gabbani è pronto per 
        esibirsi con il Magellano Tour. 
        Un live con tutti i suoi più grandi successi, compreso Occidentali’s Karma, 
        brano in vetta alla classifica airplay radio, con oltre 81 milioni di visualizzazioni 
        su youtube e certificato triplo platino per gli oltre 150mila download e streaming.
        `,
      }]} 
    />
  )
}

export default Gabbani
