import React from "react"
import EntertainmentLayout from "../../../components/entertainmentLayout/entertainmentLayout.js"

const TrePianoGen = () => {
  return (
    <EntertainmentLayout
      category="Segreteria Organizzativa"
      title="Luis Bacalov, Rita Marcotulli e Alberto Pizzo 3 Piano Generations"
      bannerImage="3pianogen.jpg"
      metaDescription="58esima ed. Festival dei 2Mondi di Spoleto: ACME presenta un concerto unico l’esibizione di tre pianisti Luis Enríquez Bacalov, Rita Marcotulli, Alberto Pizzo."
      description="Per la 58esima edizione del Festival Dei 2Mondi di Spoleto, ACME
      presenta alla direzione artistica un concerto unico nel suo genere,
      l’esibizione di tre generazioni, tre mondi sonori, tre pianisti
      dalle radici saldamente piantate nella musica classica, capaci di
      esplorare tanti altri generi musicali senza mai perdere di vista il
      pathos e la grande melodia che, se legata all’immagine, diventa
      colonna sonora."
      contents={[
        {
          date: `Giugno 2015
        Spoleto, Teatro Romano
        `,
          text: `In questa occasione la musica ed il cinema sono protagoniste di
        questa insolita “giostra” a tre pianoforti, con Luis Bacalov, Rita
        Marcotulli e Alberto Pizzo in veste di conduttori d’eccezione.
         
        Come in un’antica arena, i tre maestri degli ottantotto tasti si
        sfidano, coinvolgendo il loro pubblico in un duello musicale che
        tocca jazz, musica classica e le grandi colonne sonore, includendo
        momenti d’improvvisazione, soli a staffetta e momenti di unisono a
        due e a tre pianoforti.
         
        Con Luis Bacalov, Rita Marcotulli e Alberto Pizzo, e attraverso le
        loro dimensioni musicali, il vero omaggio è reso al pianoforte, che
        da “Principe” di questo concerto è diventato un vero e proprio
        “Gigante a tre code”.
        `,
          //image: "3pianogen.jpg"
        },
      ]}
    >
      <div className="biography">
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="authors">
                <b>Luis Enríquez Bacalov</b>
                <p>
                  <br />
                  Il Maestro è un pianista, compositore, direttore d’orchestra e
                  arrangiatore argentino, naturalizzato italiano. Ha raggiunto
                  la fama internazionale grazie alla sua attività di compositore
                  per il cinema e nella sua lunga carriera artistica ha ricevuto
                  numerosi riconoscimenti, collaborando con registi come
                  Damiani, Scola, Petri, Pasolini, Fellini e tanti altri.
                  <br />
                  <br />
                  Memorabile il premio Oscar nel 1996 per la miglior colonna
                  sonora con il film “Il postino” di Michael Radford, film
                  indimenticabile per la magistrale interpretazione di Massimo
                  Troisi.
                </p>
              </div>
            </div>
            <div className="column">
              <div className="authors">
                <b>Rita Marcotulli</b>
                <p>
                  <br />
                  Allieva del Conservatorio Santa Cecilia e del Conservatorio
                  Licinio Refice, esordisce negli anni Settanta e
                  successivamente inizia a collaborare con artisti
                  internazionali come Richard Galliano, Enrico Rava, Pat
                  Metheny, Chet Baker, Palle Danielsson, Helène La Barrière, Joe
                  Lovano, Dewey Redman, Kenny Wheeler, e molti altri.
                  <br />
                  <br />
                  Elegante pianista dalla grana melodica e dalla voce
                  strumentale molto esclusiva, si avvicina alla musica
                  brasiliana per poi approdare al jazz.
                  <br />
                  <br />
                  La collaborazione per il film Basilicata Coast to Coast di
                  Rocco Papaleo, le vale il Premio Ciak d'Oro nel 2010 per la
                  miglior Colonna Sonora ed il premio della stampa
                  cinematografica, il Nastro d'Argento nella stessa categoria e
                  il David di Donatello nel 2011 (prima donna in assoluto a
                  ricevere questo riconoscimento). Nel 2011 riceve il Premio Top
                  Jazz come miglior artista del jazz italiano dalla rivista
                  Musica Jazz. Nel 2013 è membro della giuria alla 63ª edizione
                  del Festival di Sanremo.
                </p>
              </div>
            </div>

            <div className="column">
              <div className="authors">
                <b>Alberto Pizzo</b>
                <p>
                  <br />
                  Pianista e compositore, diplomatosi nel 2004 al Conservatorio
                  S. Pietro a Majella di Napoli, vanta una lunga esperienza di
                  lavoro in Italia come pianista solista e come esecutore ed
                  autore di musiche per programmi Rai.
                  <br />
                  <br />
                  Pizzo ha calcato i palcoscenici dei maggiori festival
                  italiani, dal Ravello Festival al Bellini di Napoli, dove
                  improvvisa insieme a Chick Corea. Non vanno dimenticate
                  apparizioni internazionali come quella all’Highline Ballroom
                  di New York come unico artista italiano per il Blue Note Jazz
                  Festival, e il concerto nella sede della Borsa di Tokyo in
                  Giappone nel 2009.
                  <br />
                  <br />
                  Con il chitarrista e produttore Fabrizio Sotti co-produce On
                  The Way. Al progetto discografico, registrato dal vivo al
                  Bunker Studios di New York, partecipano musicisti quali Mino
                  Cinelu, Renzo Arbore, Toquinho e David Knopfler. Il grande
                  successo ottenuto da questo lavoro discografico lo porta ad
                  intraprendere un lungo tour in Giappone e negli Usa.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </EntertainmentLayout>
  )
}

export default TrePianoGen
