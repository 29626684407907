import React, { useContext, useEffect } from "react"
import Header from "../../components/header/header.js"
import Description from "../../components/description/description.js"
import { ThemeContext } from "../../context/ThemeContext"
import DuotoneImage from "../../components/duotoneImage/duotoneImage.js"
import Card from "../../components/card/Card.js"

import '../../assets/styles/pages/_entertainment.scss'

const CARDS = [
  {id: '1', to: '/entertainment/produzione/ilovespoleto', filename:'ilovespoleto', title:'I Love Spoleto 2018'},
  {id: '2', to: '/entertainment/produzione/lifeinumbria', filename:'lifeinumbria', title:'  Life in Umbria...', subtitle: 'lo spettacolo prende vita'},  
  {id: '3', to: '/entertainment/produzione/teatro-comico', filename:'teatrocomico', title:'Teatro Comico'},  
  {id: '4', to: '/entertainment/portfolio/dolcementeumbria', filename: 'dolcemente', title: 'Dolcemente Umbria', subtitle: 'Mercatino tipico dei dolci natalizi'},    
  {id: '5', to: '/entertainment/portfolio/dolceumbria', filename: 'dolceumbria', title: 'La Dolce Umbria', subtitle: 'Mercatino dei dolci tipici umbri'},  
  {id: '6', to: '/entertainment/portfolio/spoletotipica', filename: 'spoletotipica', title: 'Spoleto Tipica',subtitle:`Mostra mercato prodotti enogastronomici`},
]

const Artistica = () => {
  const theme = useContext(ThemeContext)

  useEffect(() => {
    theme.setBackGroundColor("red")
    theme.setOriginPage({
      label: "Direzione Artistica",
      to: "/entertainment/artistica",
    })
    theme.setVisibleFooter(true)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header
        title="Direzione&#10;Artistica"
        bannerImage="entertainment/direzione-artistica-banner"
        metaDescription="ACME cura, coordina, organizza ed indirizza progetti di eventi secondo determinati contenuti, linee e percorsi artistico-culturali."
      />
      <Description type="onTheLeft">
        <h2>
          ACME cura, coordina, organizza ed indirizza progetti di eventi secondo
          determinati contenuti, linee e percorsi artistico-culturali.
        </h2>
        <p>
          Indica protagonisti, caratteristiche, tematiche e modalità di
          svolgimento, aspetti della comunicazione, eventuali connessioni
          socio-umanitarie e scopi promozionali e d’immagine. Definisce in ogni
          dettaglio progetti di spettacoli ed eventi, in base a criteri legati a
          genere, target, obiettivi, qualità, attualità, interesse,
          contestualizzazione e indicazioni del committente. Nella fase di
          progettazione dell’evento valuta oltre agli aspetti artistici, anche
          parametri economici e tecnici,procedendo ad un’autentica analisi di
          fattibilità.
        </p>
      </Description>
      <div className="entertainment">
        <div className="columns">
          {CARDS.map(i => (
            <Card
              key={i.id}
              {...i}
              className="column is-one-third-desktop is-half-tablet is-full-mobile"
              imageComponent={DuotoneImage}
              imageType="negative"
            />
          ))}
        </div>
      </div>
    </>
  )
}

export default Artistica
