import React from "react"
import TechSoftLayout from "../../../components/techSoftLayout/techSoftLayout.js"
import {PROJECTS} from "../saas";

const BookAtMe = () => {

  return (
    <TechSoftLayout
      title="Book@Me"
      //bannerImage="ragazzo-al-portatile-connessione-wifi"
      descriptionType="onTheLeft"
      metaDescription="Book@Me booking engine + channel manager integrato in cloudper ricevere prenotazioni dirette senza commissioni, evitando così il rischio di overbooking."
      PROJECTS={PROJECTS}
      description={`Book@Me è un booking engine con channel manager integrato in cloud
       che ti consente di ricevere prenotazioni dirette senza commissioni,
       abbattendo costi per il tuo business, e di gestire tutti i tuoi
       canali web grazie al channel manager, evitando così il rischio di
       overbooking.`}
      contents={[{
        text: `A differenza dei portali, il booking engine, non ha commissioni e
        con l’integrazione totale del channel manager, potrai gestire le tue
        disponibilità, ed offerte, ma soprattutto evitare l’overbooking, in
        quanto la disponibilità viene regolata automaticamente nel momento
        in cui si ricevono prenotazioni o cancellazioni. Con Book@Me è
        possibile creare scontistiche riservate ai singoli clienti, per
        aumentarne la fidelizzazione. Il cliente, infatti, vedrà l’offerta
        con il prezzo barrato con il costo a lui riservato. L’inserimento
        nel sito web della struttura è semplice e veloce, grazie alla
        documentazione esplicativa di ciò che si deve fare. Lo sviluppo di
        API dedicate, permette la facile integrazione con qualsiasi software
        gestionale, facilitandone ancor di più l’utilizzo e l’efficienza.`,
        image: "https://static.acmeproduzioni.it/bookatme/logo-white-300.webp"
        },
        {
        text: `In particolare per le strutture ricettive, grazie all’integrazione
        con Eucleia e Connect@You potrai gestire la Brand Reputation e
        l’accesso al WiFi della struttura, offrendo ai tuoi clienti un
        servizio di accoglienza dalla prenotazione, alla permanenza, fino a
        quando non eseguiranno il check-out.
        
        Un “concierge” a disposizione 24 h per ogni tuo singolo cliente!`,
        image: "bookatme-screen",
        linkText:"Vai al sito",
        to:"http://www.bookatme.cloud/"
        }
      ]}
      // firstImage={"cau/phone1.png"}
      // secondImage={"cau/phone2.png"}
    />
  )
}

export default BookAtMe
