import React, { useContext, useEffect } from "react"
import Header from "../components/header/header.js"
import { ThemeContext } from "../context/ThemeContext"
import Card from "../components/card/Card.js"
import Marquee from "../components/marquee/marquee.js"
import { Link } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay } from 'swiper';
import duotoneImage from "../components/duotoneImage/duotoneImage"
import '../assets/styles/pages/_ourworks.scss'



const OurWorks = () => {
  const theme = useContext(ThemeContext)

  SwiperCore.use([Autoplay])

  const ENTERTAINMENT = [
    {
      title: "Anno Menottiano",
      subtitle: "Centenario Maestro Gian Carlo Menotti",
      filename: "lavori-anno-menottiano.jpg",
      to: "/entertainment/portfolio/annomenottiano",
      swiperClassName: "column is-4-desktop is-half-tablet is-full-mobile"
    },
    {
      title: "Enrico Brignano",
      subtitle: "Evolushow",
      filename: "brignano.jpg",
      to: "/entertainment/produzione/brignano",
      swiperClassName: "column is-4-desktop is-half-tablet is-full-mobile"
    },
    {
      title: "Life in Umbria...",
      subtitle: "lo spettacolo prende vita",
      filename: "lifeinumbria.jpg",
      to: "/entertainment/produzione/lifeinumbria",
      swiperClassName: "column is-4-desktop is-half-tablet is-full-mobile"
    },
    {
      title: "Luis Bacalov, Rita Marcotulli e Alberto Pizzo",
      subtitle: "3 Piano Generations",
      filename: "3pianogen.jpg",
      to: "/entertainment/produzione/trepianogen",
      swiperClassName: "column is-2-desktop is-half-tablet is-full-mobile"
    },
    {
      title: "I Love Spoleto 2018",
      subtitle: "",
      filename: "lavori-2018-i-love-spoleto.jpg",
      to: "/entertainment/produzione/ilovespoleto",
      swiperClassName: "column is-4-desktop is-half-tablet is-full-mobile"
    },
    {
      title: "Teatro Comico",
      subtitle: "",
      filename: "battista.jpg",
      to: "/entertainment/produzione/teatro-comico",
      swiperClassName: "column is-4-desktop is-half-tablet is-full-mobile"
    },
  ]

  const TECHNOLOGY = [
    {
      title: "Lorenzo “Jovanotti” Cherubini",
      subtitle: "Jova Beach Party Tour",
      to: "/tech-soft/custom/jovabeachparty",
      filename: "jova-app.jpg",
      swiperClassName: "column is-2-desktop is-half-tablet is-full-mobile"
    },
    {
      title: "Pinguini Tattici Nucleari",
      subtitle: "#machilavrebbemaidetto Tour",
      to: "/tech-soft/custom/ptn",
      filename: "pinguini-app.jpg",
      swiperClassName: "column is-4-desktop is-half-tablet is-full-mobile"
    },
    {
      title: "Castello di Baccaresca",
      subtitle: "",
      to: "/tech-soft/wifi/baccaresca",
      filename: "castello-baccaresca-piscina.jpg",
      swiperClassName: "column is-2-desktop is-half-tablet is-full-mobile"
    },
    {
      title: "Bagno Tiki 26",
      subtitle: "",
      to: "/tech-soft/wifi/bagnotiki26",
      filename: "bagno-tiki26-rimini.jpg",
      swiperClassName: "column is-4-desktop is-half-tablet is-full-mobile"
    },
    {
      title: "Rossopomodoro",
      subtitle: "",
      to: "/tech-soft/wifi/rossopomodoro",
      filename: "ristorante-rossopomodoro.jpg",
      swiperClassName: "column is-2-desktop is-half-tablet is-full-mobile"
    },    
    {
      title: "Le Befane Shopping Centre",
      subtitle: "",
      to: "/tech-soft/wifi/lebefane",
      filename: "le-befane-shopping-center.jpg",
      swiperClassName: "column is-4-desktop is-half-tablet is-full-mobile"
    },        
  ]

  const CINEMA = [
    {
      title: "Allacciate le cinture",
      to: "https://www.imdb.com/title/tt3425034/?ref_=nm_flmg_snd_9",
      filename: "allacciate-le-cinture-screen.jpg",
      year: "2019",
      swiperClassName: "column is-4-desktop is-half-tablet is-full-mobile",
      subtitle:"(sound-mixer)"
    },
    {
      title: "Caccia al tesoro",
      to: "https://www.imdb.com/title/tt6918790/?ref_=nm_flmg_snd_6",
      filename: "caccia-al-tesoro-screen.jpg",
      year: "2017",
      swiperClassName: "column is-2-desktop is-half-tablet is-full-mobile",
      subtitle:"(sound-mixer)"
    },
    {
      title: "Attraverso i miei occhi",
      to: "https://www.imdb.com/title/tt1478839/?ref_=nm_flmg_snd_3",
      filename: "attraverso-i-miei-occhi-screen.jpg",
      year: "2019",
      swiperClassName: "column is-4-desktop is-half-tablet is-full-mobile",
      subtitle:"(sound-mixer)"
    },    
    {
      title: "Non si ruba a casa dei ladri",
      to: "https://www.imdb.com/title/tt6218374/?ref_=nm_flmg_snd_7",
      filename: "non-si-ruba-a-casa-dei-ladri-screen.jpg",
      year: "2016",
      swiperClassName: "column is-2-desktop is-half-tablet is-full-mobile",
      subtitle:"(sound-mixer)"
    },
    {
      title: "Le ultime 56 ore",
      to: "https://www.imdb.com/title/tt1440231/?ref_=nm_flmg_snd_10",
      filename: "le-ultime-56-ore-screen.jpg",
      year: "2016",
      swiperClassName: "column is-4-desktop is-half-tablet is-full-mobile",
      subtitle:"(sound-mixer)"      
    },
    {
      title: "Una festa esagerata",
      to: "https://www.imdb.com/title/tt7730492/?ref_=nm_flmg_snd_5",
      filename: "una-festa-esagerata-screen.jpg",
      year: "2018",
      swiperClassName: "column is-2-desktop is-half-tablet is-full-mobile",
      subtitle:"(sound-mixer)"
    },
    
  ]

  useEffect(() => {
    theme.setBackGroundColor("white")
    theme.setVisibleFooter(true)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div id="ourworks">
      <Header title="I nostri lavori" />      
      <Link to="/tech-soft/technology">
        <Marquee text="Tech &amp; Software" speed="normal" />
      </Link>
        <div className="tiles ourWorksSlider">
          <Swiper 
            slidesPerView={'auto'} grabCursor={true} loop= {true} autoplay={{ delay: 3000, disableOnInteraction: false }}>
            {TECHNOLOGY.map(tech => (
              <SwiperSlide key={tech.title} className={tech.swiperClassName} >                
                <Card imageComponent={duotoneImage} imageType="blue" {...tech}/>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <Link to="/entertainment/entertainment">
        <Marquee text="Entertainment" speed="fast" />
      </Link>
      <div className="tiles ourWorksSlider">
        <Swiper 
          slidesPerView={'auto'} grabCursor={true} loop={true} autoplay={{ delay: 2500, disableOnInteraction: false }}>
          {ENTERTAINMENT.map(entertainment => (
            <SwiperSlide key={entertainment.title} className={entertainment.swiperClassName}>
              <Card imageComponent={duotoneImage} imageType="red" {...entertainment}/>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <Link to="/cinemaspottv">
        <Marquee text="Cinema, Spot &amp; Tv" speed="slow" />
      </Link>
        <div className="tiles ourWorksSlider">
          <Swiper 
            slidesPerView={'auto'} grabCursor={true} loop= {true} autoplay={{ delay: 3500, disableOnInteraction: false }}>
            {CINEMA.map(cinema => (
              <SwiperSlide key={cinema.title} className={cinema.swiperClassName}>               
               <Card imageComponent={duotoneImage} imageType="sepia" {...cinema} externalLink={true}/>
              </SwiperSlide>
            ))}
          </Swiper>
      </div>
    </div>
  )
}

export default OurWorks
