import React, { useContext, useEffect } from "react"
import { Helmet } from "react-helmet"
import { ThemeContext } from "../context/ThemeContext"
import { motion } from "framer-motion"
import "../assets/styles/pages/_index.scss"
import {Link} from "gatsby";

const IndexPage = () => {
  const theme = useContext(ThemeContext)


  useEffect(() => {
    theme.setBackGroundColor("white")
    theme.setOriginPage({ label: "Home", to: "/" })
    theme.setVisibleFooter(true)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ACME Produzioni | App, software per hospitality e organizzazione eventi</title>
        <meta name="description" content="ACME nasce nel mondo dell’intrattenimento;
        nel tempo ci siamo occupati di diverse attività come lo sviluppo
        di software e APP puntando sempre all’eccellenza." />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="home">
          <div className="columns">
              <div className="column is-half is-flex-direction-column is-justify-content-flex-start	is-align-content-flex-start	">
                  <h2>Creatività, tecnologia e gestione integrata: ACME Produzioni trasforma le idee in software ed
                      eventi unici.
                      <br/>
                      Dalla produzione artistica alla personalizzazione digitale, offriamo soluzioni su misura per un
                      intrattenimento indimenticabile e una connettività senza confini.
                  </h2>

                  <h1>
                      Universi connessi<br/>
                      Noi creiamo, tu vivi
                  </h1>
              </div>
              <div className={"Column is-half"}>
                  <img src={"https://static.acmeproduzioni.it/acme/icon-white-900.webp"} alt={"ACME Produzioni Logo"}/>
              </div>
          </div>
          <div className="columns">
              <Link to={"/tech-soft/saas"} className="home-link column is-one-third ">
                  <motion.div  whileHover={{
                      scale: 1.1,
                      transition: { duration: 0.3 },
                  }} className="home-box">
                      <h3>I nostri Software</h3>
                      <p>
                          Scopri i nostri software per l'ospitalità e l'organizzazione di eventi
                      </p>
                  </motion.div>
              </Link>
              <Link to={"/tech-soft/custom/jovabeachparty2022"} className="home-link column is-one-third ">
              <motion.div  whileHover={{
                  scale: 1.1,
                  transition: { duration: 0.3 },
              }} className="home-box">
                  <h3>Jova Beach Party</h3>
                  <p>
                      Un evento unico, un'esperienza indimenticabile al fianco di Jovanotti
                  </p>
              </motion.div>
              </Link>
              <Link to={"/entertainment"} className="home-link column is-one-third ">
              <motion.div  whileHover={{
                  scale: 1.1,
                  transition: { duration: 0.3 },
              }} className="home-box">
                  <h3>Le Nostre Produzioni</h3>
                  <p>
                        Scopri le nostre produzioni artistiche e i nostri allestimenti
                  </p>
              </motion.div>
              </Link>
          </div>
      </div>
    </>
  )
}

export default IndexPage
