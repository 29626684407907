import React, { useContext, useEffect } from "react"
import Header from "../../components/header/header.js"
import Description from "../../components/description/description.js"
import { ThemeContext } from "../../context/ThemeContext"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay } from "swiper"
import Card from "../../components/card/Card.js"

import "../../assets/styles/pages/_techSoft.scss"

const WebSitesCreation = () => {
  const theme = useContext(ThemeContext)

  const LOGOS = [
    {
      id: 1,
      filename: "websites/roseto-website.png",
      to: "https://ristoranteilroseto.it/",
      swiperClassName: "column is-2-desktop is-half-tablet is-full-mobile",
      externalLink: true,
    },
    {
      id: 2,
      filename: "websites/hotel-pax-website.png",
      to: "http://www.hotelpaxassisi.com/",
      swiperClassName: "column is-2-desktop is-half-tablet is-full-mobile",
      externalLink: true,
    },
    {
      id: 3,
      filename: "websites/alveare-website.png",
      to: "https://ristorantealveare.it/",
      swiperClassName: "column is-2-desktop is-half-tablet is-full-mobile",
      externalLink: true,
    },    
    {
      id: 4,
      filename: "websites/domus-pacis-website.png",
      to: "https://www.domuspacis.it/",
      swiperClassName: "column is-2-desktop is-half-tablet is-full-mobile",
      externalLink: true,
    },    
    {
      id: 5,
      filename: "websites/i-love-spoleto-website.png",
      to: "http://ilovespoleto.it/",
      swiperClassName: "column is-2-desktop is-half-tablet is-full-mobile",
      externalLink: true,
    },
    {
      id: 6,
      filename: "websites/egoricarica-website.png",
      to: "http://egoricarica.me/",
      swiperClassName: "column is-2-desktop is-half-tablet is-full-mobile",
      externalLink: true,
    }    
  ]

  useEffect(() => {
    theme.setBackGroundColor("grey")
    theme.setOriginPage({
      label: "Infrastrutture reti",
      to: "/tech-soft/it-service",
    })
    theme.setVisibleFooter(true)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  SwiperCore.use([Autoplay])

  return (
    <>
      <Header
        title="Realizzazione&#10;Siti web"
        metaDescription="ACME per andare incontro alle esigenze dei suoi clienti ha deciso di dedicarsi anche alla realizzazione di siti web."
      />
      <Description type="onTheLeft">
        <h2>
          Ecco l’ultima sfida che ha deciso di affrontare ACME!
          <br />
          ACME per andare incontro alle esigenze dei suoi clienti e soddisfare
          al meglio ogni loro richiesta ha deciso di dedicarsi anche alla
          realizzazione di siti web.
        </h2>
        <p>
          Il pacchetto di servizi offerti ormai è sempre più ampio, e ACME
          riesce a conciliare le esigenze di clienti effettivi e potenziali
          grazie alle eccellenti doti dei suoi collaboratori, il risultato
          ottenuto in questa sfida è la fidelizzazione del cliente.<br/><br/>
          ACME crea il tuo sito internet!
        </p>
      </Description>
      <div className="itService">
        <h2>Siti web realizzati da noi</h2>
        <div id="websites-slide" className="tile">
          <Swiper
            spaceBetween={10}
            slidesPerView={"auto"}
            grabCursor={true}
            loop={true}
            autoplay={{ delay: 2000, disableOnInteraction: false }}
          >
            {LOGOS.map(logo => (
              <SwiperSlide
                key={logo.id}
                virtualIndex={logo.id}
                className={logo.swiperClassName}
              >
                <Card {...logo} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  )
}

export default WebSitesCreation
