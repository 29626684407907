import React, { useContext, useEffect } from "react"
import Header from "../../components/header/header.js"
import Description from "../../components/description/description.js"
import { ThemeContext } from "../../context/ThemeContext"
import DuotoneImage from "../../components/duotoneImage/duotoneImage.js"
import Card from "../../components/card/Card.js"

import '../../assets/styles/pages/_entertainment.scss'

const CARDS = [
  {id: '1', to: '/entertainment/produzione/ilovespoleto', filename:'kataklo', title:'I Love Spoleto 2018'},
  {id: '2', to: '/entertainment/produzione/lifeinumbria', filename:'lifeinumbria', title:'  Life in Umbria...', subtitle: 'lo spettacolo prende vita'},  
  {id: '3', to: '/entertainment/produzione/teatro-comico', filename:'teatrocomico', title:'Teatro Comico'},  
  {id: '4', to: '/entertainment/portfolio/dolcementeumbria', filename: 'dolcemente', title: 'Dolcemente Umbria', subtitle: 'Mercatino tipico dei dolci natalizi'},    
  {id: '5', to: '/entertainment/portfolio/dolceumbria', filename: 'dolceumbria', title: 'La Dolce Umbria', subtitle: 'Mercatino dei dolci tipici umbri'},  
  {id: '6', to: '/entertainment/portfolio/spoletotipica', filename: 'spoletotipica', title: 'Spoleto Tipica',subtitle:`Mostra mercato prodotti enogastronomici`},
  {id: '7', to: '/entertainment/portfolio/lifeingubbio', filename: 'lifeingubbio', title: 'Life in Gubbio', subtitle: 'lo spettacolo prende vita'},
]

const Tecnica = () => {
  const theme = useContext(ThemeContext)

  useEffect(() => {
    theme.setBackGroundColor("red")
    theme.setOriginPage({
      label: "Direzione Tecnica",
      to: "/entertainment/tecnica",
    })
    theme.setVisibleFooter(true)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header
        title="Direzione&#10;Tecnica"
        bannerImage="entertainment/direzione-tecnica-banner.jpg"
        metaDescription="ACME Coordina e gestisce lo staff tecnico: luci, scena, strutture e sicurezza, valuta la fattibilità dei progetti di allestimento."
      />
      <Description type="onTheLeft">
        <h2>
          ACME coordina e gestisce tutto lo staff tecnico: luci, scena,
          strutture e sicurezza. Inoltre, valuta la fattibilità dei progetti di
          allestimento sulla base di questioni logistiche e tecniche.
        </h2>
        <p>
          Nel caso in cui venga assorbita anche la figura di direttore di
          produzione, si occupa della gestione del budget. Rappresenta il punto
          di riferimento per i rapporti con la direzione artistica, i fornitori
          di servizi, apparecchiature e materiali. Stabilisce la pianificazione
          di tutto lo staff, definendo orari, mansioni e modalità di lavoro. Al
          termine dell’evento avrà cura di riconsegnare la location in perfette
          condizioni.
        </p>
      </Description>
      <div className="entertainment">
        <div className="columns">
          {CARDS.map(i => (
            <Card
              key={i.id}
              {...i}
              className="column is-one-third-desktop is-half-tablet is-full-mobile"
              imageComponent={DuotoneImage}
              imageType="negative"
            />
          ))}
        </div>
      </div>
    </>
  )
}

export default Tecnica
