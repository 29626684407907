import React from "react"
import Card from "../card/Card.js"

import "./style.scss"


const Tile = ({ offset, shape, to, filename, alt, subtitle, children, target, imageComponent, imageType ='default' }) => {
  return (

    <div className={`tile is-parent is-${shape + (offset ? ` column is-offset-${offset}` : ``)}`}>
      <Card 
        title={alt}
        subtitle={subtitle}
        target={target}
        className="tile is-child"
        to={to}
        imageComponent={imageComponent}
        imageType={imageType}
        filename={filename}>
          {children}
        </Card>
    </div>
  )
}

export default Tile
