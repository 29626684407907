import React from "react";
import { graphql, Link } from "gatsby";
import "./style.scss";

export const query = graphql`
  query PostTemplate($slug: String!) {
    post: strapiAcmeWebsiteBlogs(slug: { eq: $slug }) {      
      strapiId
      title
      slug
      published_at
      body
    }
  }
`;


export default function PostTemplate({data}) {

  
  const post = data.post;
  const { title, body, author, date } = post;
  
  return (
    <>
      <div className='blogTemplate'>        
        <h1 className="blogTemplate-title">{ title }</h1>
        <p className='blogTemplate-posted-by'>Posted by { author } on { date }</p>
        <div>{body}</div>

        <Link to="/blog">Back to blogs</Link>
      </div>
    </>
  )
};

