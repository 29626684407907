import React, { useContext, useEffect } from "react"
import Header from "../../components/header/header.js"
//import Description from "../../components/description/description.js"
import { ThemeContext } from "../../context/ThemeContext"
import Tile from "../../components/tile/tile.js"

export const PROJECTS = [
  { title: "Connect@You", subtitle: "servizio di hotspot, WiFi gratuita, facile e sicura", to: "/tech-soft/saas/connectatyou", filename: "connect.png" },
  { title: "Book@Me", subtitle: "booking engine con channel manager in cloud", to: "/tech-soft/saas/bookatme", filename: "bookatme.png" },
  { title: "Eucleia", subtitle: "software in cloud per accrescere la reputazione online", to: "/tech-soft/saas/eucleia", filename: "eucleia.png" },
 // { title: "Wellify", subtitle: "software gestionale in cloud per parrucchieri ed estetisti", to: "/tech-soft/saas/wellify", filename: "wellify.png" },
  { title: "Primo POS", subtitle: "smart menu e gestione degli ordini", to: "/tech-soft/saas/primo", filename: "primo.png" },
  { title: "Enma", subtitle: "Manage Pass for Events", to: "/", filename: "enma.png" }
]

const Saas = () => {
  const theme = useContext(ThemeContext)

  useEffect(() => {
    theme.setBackGroundColor("grey")
    theme.setOriginPage({
      label: "I nostri prodotti",
      to: "/tech-soft/saas",
    })
    theme.setVisibleFooter(true)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header
        title="I nostri prodotti"
        //bannerImage="chip"
        metaDescription="ACME grazie al suo team di sviluppo realizza software e APP puntando a migliorare l’user experience dell’utente."
      />
      <div className="section" id="ACME-Saas"/>
      {/* <Description type="onTheLeft">
        <h2>
          ACME grazie al suo team di sviluppo realizza software e APP puntando a
          migliorare l’user experience dell’utente.
        </h2>
        <p>
          Dalla professionalità dei nostri programmatori nascono software e APP
          per i settori hospitality e wellness, tutti estremamente
          personalizzabili sia a livello grafico che di contenuti, con strutture
          usabili e capaci di automatizzare i processi d’interazione e
          integrazione con i differenti gestionali aziendali. ACME segue a 360
          gradi la realizzazione dei suoi prodotti dall’ideazione allo sviluppo,
          passando per test e correzione bug, sino alla manutenzione e
          implementazione.
        </p>
      </Description> */}
      <div className="tiles">
        <div className="columns is-multiline m-0">
          {PROJECTS.map(project => (
            <Tile
              shape={"4"}
              key={project.title}
              to={project.to}
              filename={project.filename}
              alt={project.title}
              subtitle={project.subtitle}
            />
          ))}
        </div>
      </div>
    </>
  )
}

export default Saas
