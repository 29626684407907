import React from "react"
import TechSoftLayout from "../../../components/techSoftLayout/techSoftLayout.js"

const RossoPomodoro = () => {
  return (
    <TechSoftLayout
      title="RossoPomodoro &#10;Rimini"
      descriptionType="customAndWifi"
      metaDescription="Per Rosso Pomodoro abbiamo migliorato le performance della WiFi e garantito servizi di autenticazione clienti, connessione."
      contents={[{
        date:`Committente: Food & Co srl | Rosso Pomodoro Rimini
        Anno: 2015-2020
        
        `,
        text: `
        Il Ristorante Rossopomodoro di Rimini è un locale di respiro
        internazionale aperto 365 giorni l’anno con orario continuato dalle
        11:00 sino all’ultimo cliente rimasto.
        
        All’interno del ristorante potrete gustare le specialità: dalla
        pizza napoletana verace alle crudità di mare fino al pesce fresco
        cucinato sapientemente dagli chef.`,
        image: "rossopomodoro.png"
        },
        {
        text: {
            partOne: `Esigenze del cliente
            
            • Migliorare le performance della WiFi all’interno e all’esterno del ristorante
            • Avere uno strumento di sicurezza per le connessioni ad internet
            • Rendere estremamente semplice e veloce la procedura di autenticazione per i clienti`,
            partTwo: `Le nostre soluzioni

            • Fornitura apparati per distribuzione del servizio WiFi all’interno e all’esterno del ristorante
            • Captive portal Connect@You per autenticazione dei clienti della struttura
            • Servizio di assistenza  `
          }
        }
      ]}      
    />
  )
}

export default RossoPomodoro
