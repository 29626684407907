
import Image from "./components/image/image"
import NegativeImage from "./components/image/NegativeImage"
import SeppiaImage from "./components/image/SeppiaImage"
import RedishImage from "./components/image/RedishImage"
import BlueishImage from "./components/image/BlueishImage"
import {useRef} from "react";
import OrangshImage from "./components/image/OrangshImage";

export const ImageFactory = type => {

    switch(type)
    {
        case 'negative':
            return NegativeImage
        case 'sepia':
            return SeppiaImage
        case 'red':
            return RedishImage
        case 'blue':
            return BlueishImage
        case 'orange':
            return OrangshImage
        default:
            return Image
    }
}

export const CookieBarTheme = type => {
    switch(type)
    {
        case 'white':
            return { background:"white", color:"black", backgroundButton: "#38b6e7", colorButton: "white" }
        case 'red':
            return { background:"var(--entertainment-color)", color:"black", backgroundButton: "#30d0b8", colorButton: "white" }
        case 'grey':
            return { background:"var(--technology-color)", color:"white", backgroundButton: "#DEDACA", colorButton: "#435560" }
        case 'loafer':
            return { background:"var(--cinema-color)", color:"var(--cinema-color-text)", backgroundButton: "white", colorButton: "black" }
        default:
            return { background:"white", color:"black", backgroundButton: "#38b6e7", colorButton: "white" }
    }
}


export function usePrevious(value) {
    const currentRef = useRef(value)
    const previousRef = useRef()
    if (currentRef.current !== value) {
        previousRef.current = currentRef.current
        currentRef.current = value
    }
    return previousRef.current
}
