import React, { useContext, useEffect } from "react"
import Header from "../../components/header/header.js"
import Description from "../../components/description/description.js"
import { ThemeContext } from "../../context/ThemeContext"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay } from 'swiper';
import Card from "../../components/card/Card.js"

import '../../assets/styles/pages/_techSoft.scss'

const ItServiceManagement = () => {
  const theme = useContext(ThemeContext)

const LOGOS = [
    { id: 1, filename: "baccaresca.png", to: "/tech-soft/wifi/baccaresca", swiperClassName: "column is-2-desktop is-half-tablet is-full-mobile" },      
    { id: 2, filename: "barbecue.png", to: "/tech-soft/wifi/piacerebarbecue", swiperClassName: "column is-2-desktop is-half-tablet is-full-mobile" },        
    { id: 3, filename: "chocostore.png", to: "/tech-soft/wifi/eurochocolate", swiperClassName: "column is-2-desktop is-half-tablet is-full-mobile" },                   
    { id: 4, filename: "comunericcione.png", to: "/tech-soft/wifi/riccione", swiperClassName: "column is-2-desktop is-half-tablet is-full-mobile" }, 
    { id: 5, filename: "domuspacis.png", to: "/tech-soft/wifi/domuspacis", swiperClassName: "column is-2-desktop is-half-tablet is-full-mobile" },        
    { id: 6, filename: "corona.png", to: "/tech-soft/wifi/corona", swiperClassName: "column is-2-desktop is-half-tablet is-full-mobile" },        
    { id: 7, filename: "ferrara.png", to: "/tech-soft/wifi/ferrara", swiperClassName: "column is-2-desktop is-half-tablet is-full-mobile" },        
    { id: 8, filename: "gluten.png", to: "/tech-soft/wifi/glutenfreefest", swiperClassName: "column is-2-desktop is-half-tablet is-full-mobile" },        
    { id: 9, filename: "lebefane.png", to: "/tech-soft/wifi/lebefane", swiperClassName: "column is-2-desktop is-half-tablet is-full-mobile" },        
    { id: 10, filename: "malto.png", to: "/tech-soft/wifi/maltolivello", swiperClassName: "column is-2-desktop is-half-tablet is-full-mobile" },        
    { id: 11, filename: "motor.png", to: "/tech-soft/wifi/motordays", swiperClassName: "column is-2-desktop is-half-tablet is-full-mobile" },        
    { id: 12, filename: "panenostrum.png", to: "/tech-soft/wifi/panenostrum", swiperClassName: "column is-2-desktop is-half-tablet is-full-mobile" },
    { id: 13, filename: "rossopomodoro.png", to: "/tech-soft/wifi/rossopomodoro", swiperClassName: "column is-2-desktop is-half-tablet is-full-mobile" },
    { id: 14, filename: "todi.png", to: "/tech-soft/wifi/todiappydays", swiperClassName: "column is-2-desktop is-half-tablet is-full-mobile" },
    { id: 15, filename: "tiki26.png", to: "/tech-soft/wifi/bagnotiki26", swiperClassName: "column is-2-desktop is-half-tablet is-full-mobile" },  
    
  ]

  useEffect(() => {
    theme.setBackGroundColor("grey")
    theme.setOriginPage({
      label: "Infrastrutture reti",
      to: "/tech-soft/it-service",
    })
    theme.setVisibleFooter(true)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  SwiperCore.use([Autoplay])

  return (
    <>
      <Header
        title="Infrastrutture reti"
        //bannerImage="chip"
        metaDescription="Realizziamo a seconda delle esigenze del cliente una rete WiFi temporanea o permanente, rivolta ad attività commerciali private o a reti pubbliche – cittadine."
      />
      <Description type="onTheLeft">
        <h2>
          ACME può realizzare a seconda delle esigenze del cliente una rete
          WiFi temporanea, per il singolo evento, o permanente, rivolta ad
          attività commerciali private o a reti pubbliche – cittadine.
        </h2>
        <p>
          Access point e ponti radio utilizzati sono di ultima generazione, con
          ingombro ridotto, adattabili all’ambiente per ridurre l’impatto visivo
          e aggiornabili da remoto. Gli strumenti di rete certificati per
          l’installazione outdoor e indoor, rispettano tutti gli standard europei
          e nazionali della legge sulle telecomunicazioni. Inoltre, le funzioni
          di “client isolation” permettono di salvaguardare gli utenti da
          possibili intrusioni sui vari livelli di rete, in conformità delle
          normative in materia di privacy. ACME può fornire in base alle esigenze
          dei clienti anche servizi di connettività.
        </p>
      </Description>
      <div className="itService">
        <h2>Tutte le nostre infrastrutture reti</h2>
        <div id="techSoft-slide" className="tile">
          <Swiper spaceBetween={50} slidesPerView={"auto"} grabCursor={true} loop={true} autoplay={{ delay: 2000, disableOnInteraction: false }}>
            {LOGOS.map(logo => (
              <SwiperSlide key={logo.id} virtualIndex={logo.id} className={logo.swiperClassName}>
                <Card {...logo} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  )
}

export default ItServiceManagement
