import React from "react"
import EntertainmentLayout from "../../../components/entertainmentLayout/entertainmentLayout.js"

const Granatieri = () => {
  return (
    <EntertainmentLayout
      category="Segreteria Organizzativa"
      title="Cerimonia di avvicendamento &#10;Comandante "
      bannerImage="granatieri.jpg"
      metaDescription="Brigata Meccanizzata Granatieri di Sardegna 2° Battaglione “Cengio”"
      description="Brigata Meccanizzata Granatieri di Sardegna 2° Battaglione
      “Cengio”"
      contents={[{
        date:`Committente: Comune di Spoleto
        Settembre 2011
        Spoleto, Caserma Garibaldi
        `,
        text:`La Brigata Meccanizzata Granatieri di Sardegna è una delle Grandi
        Unità destinate alla "presenza e sorveglianza", con particolare
        riguardo alla città di Roma. Costituita nel 1831 con i reggimenti
        "Granatieri" e "Cacciatori" prende parte alle tre guerre
        d'Indipendenza, alla repressione del brigantaggio, alla prima ed
        alla seconda guerra mondiale, durante la quale è di presidio nella
        Capitale dove combatterà nelle tristi giornate dell'armistizio.
       
        Oggi la grande unità ha configurazione pluriarma, è posto alla guida
        un Generale di Brigata ed è alimentata con personale Volontario, in
        Ferma ed in Servizio Permanente. La Brigata si compone di un reparto
        comando, un reggimento granatieri, uno di cavalleria e uno
        d'artiglieria. Inoltre ha alle dipendenze il 2° Battaglione
        “Cengio”, distaccato nella sede di Spoleto, composto da una
        compagnia di comando e supporto e da due compagnie fucilieri.`,
        //image: "granatieri.jpg"  
      }]} 
    />
  )
}

export default Granatieri
