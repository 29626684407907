import React from "react"
import EntertainmentLayout from "../../../components/entertainmentLayout/entertainmentLayout.js"

const LifeInGubbio = () => {
  return (
    <EntertainmentLayout
      category="Segreteria Organizzativa"
      title="Life in Gubbio 2009... &#10;lo spettacolo prende vita"
      bannerImage="lifeingubbio.jpg"
      metaDescription="Life in Gubbio uno spettacolo di Paolo Bonolis. Ospiti: G. Proietti, D. Fo, N. Piovani, R. Vecchioni, E. De Luca, C. Lucarelli, V. Capossela"
      description="Life in Gubbio una manifestazione di immagini, musica e parole
      condotta da Paolo Bonolis, e ideata da Michele Francesco Afferrante
      e Filippo Mauceri (autori del programma televisivo Il Senso della
      Vita)."
      contents={[{
        date:`Agosto 2009
        Gubbio
        Chiostro Convento di San Francesco 
        Piazza Grande
        Palazzo dei Consoli
        `,
        text:`Lo spettacolo all’insegna di leggerezza, musica di qualità e buon
        umore, ruota intorno all’incontro di Paolo Bonolis con personaggi
        illustri provenienti dal mondo della cultura e dello spettacolo con
        l’obiettivo di dare vita a momenti di grandi intensità.
                  
        Un momento condiviso di riflessione dove è protagonista una parola
        forte, parola che non ha bisogno di altro, che cattura l’attenzione
        per la tensione che riesce a creare.
         
        Non parole tra le tante ma parole di una vita.
                  
        Nel corso delle serate vengono assegnati i premi Il senso di una
        vita a: Gigi Proietti, Dario Fo, Nicola Piovani, l’Associazione
        Isreaeliano-Palestinese The Parents Circle.
          
        Life in Gubbio ospita anche altri appuntamenti con l’intervento di
        artisti quali Vinicio Capossela e Vincenzo Costantino, Roberto
        Vecchioni, Umberto Galimberti, Carlo Lucarelli, Erri De Luca         
       `,
        //image: "lifeingubbio.jpg"  
      }]} 
      guests={
        <>
          <b>Ospiti</b>
          <br />
          Stefano Di Battista Jazz Quartet,
          <br />
          Giovanni Baglioni,
          <br />
          Erri De Luca
        </>
      }
      firstAuthor={
        <>
          <b>Fra Sidival Fila</b>
          <br />
          <p>Autore del Manifesto Life In 2010</p>
        </>
      }
    />
  )
}

export default LifeInGubbio
