import React from "react"
import { Link } from "gatsby"

import "./style.scss"
import { ImageFactory } from "../../helper";

const Card = ({to, filename, children, title, subtitle, className = '', imageComponent, imageType='default', target='_self', externalLink }) => {

  const Image = imageComponent ? imageComponent : ImageFactory(imageType);
  return (
    <>
    {externalLink ? <article className={`acme-card ${className}`}>
    <a href={to} target="_blank" rel="noreferrer">
        <Image filename={filename} alt={title||children||''} type={imageType}/>
        <span className="acme-card__title">
        {children}<b>{title}</b>
        {subtitle && <span className="acme-card__subtitle">{subtitle}</span>}
        </span>
    </a> 
    </article>
      :
      <article className={`acme-card ${className}`}>
    <Link to={to} target={target}>
        <Image filename={filename} alt={title||children||''} type={imageType}/>
        <span className="acme-card__title">
        {children}<b>{title}</b>
        {subtitle && <span className="acme-card__subtitle">{subtitle}</span>}
        </span>
    </Link> 
    </article>}
    </>
  )
  
}

export default Card
