import React from "react"
import "./style.scss"

const Marquee = ({ text, speed }) => {
  return (
    <>
      <div className="blackBorders">
        <h1 className="marquee">
          <span className={speed} aria-hidden="true">
            {text}
          </span>
          <span className={speed} aria-hidden="true">
            {text}
          </span>
          <span className={speed} aria-hidden="true">
            {text}
          </span>
          <span className={speed} aria-hidden="true">
            {text}
          </span>
          <span className={speed} aria-hidden="true">
            {text}
          </span>
        </h1>
      </div>
    </>
  )
}

export default Marquee
