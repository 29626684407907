import React, { useContext, useEffect } from "react"
import Header from "../../components/header/header.js"
import Description from "../../components/description/description.js"
import { ThemeContext } from "../../context/ThemeContext"
import DuotoneImage from "../../components/duotoneImage/duotoneImage.js"
import Card from "../../components/card/Card.js"

import '../../assets/styles/pages/_entertainment.scss'

const CARDS = [
  {id: '1', to: '/entertainment/produzione/alice', filename:'alice', title:'Alice nel Paese delle Meraviglie', subtitle: 'Il Musical'},
  {id: '2', to: '/entertainment/produzione/venditti', filename:'venditti', title:'Antonello Venditti', subtitle: 'Special Event Live'},
  {id: '3', to: '/entertainment/produzione/cochierenato', filename:'cochirenato', title:'Cochi e Renato', subtitle: 'Finchè c\'è la salute'},
  {id: '4', to: '/entertainment/portfolio/dolceumbria', filename: 'dolceumbria', title: 'La Dolce Umbria', subtitle: 'Mercatino dei dolci tipici umbri'},
  {id: '5', to: '/entertainment/produzione/gabbani', filename:'gabbani', title:'Francesco Gabbani', subtitle: 'Tour Magellano'},
  {id: '6', to: '/entertainment/produzione/allevi', filename:'allevi', title:'Giovanni Allevi', subtitle: 'Alien World Tour'},  
  {id: '7', to: '/entertainment/produzione/ilovespoleto', filename:'ilovespoleto', title:'I Love Spoleto 2018'},
  {id: '8', to: '/entertainment/portfolio/dolcementeumbria', filename: 'dolcemente', title: 'Dolcemente Umbria', subtitle: 'Mercatino tipico dei dolci natalizi'},  
  {id: '9', to: '/entertainment/produzione/lifeinumbria', filename:'lifeinumbria', title:'  Life in Umbria...', subtitle: 'lo spettacolo prende vita'},
  {id: '10', to: '/entertainment/portfolio/lifeingubbio', filename: 'lifeingubbio', title: 'Life in Gubbio', subtitle: 'lo spettacolo prende vita'},
  {id: '11', to: '/entertainment/produzione/mannarino', filename:'mannarino', title:'Mannarino', subtitle: 'Tour Al Monte Live'},
  {id: '12', to: '/entertainment/produzione/teatro-comico', filename:'teatrocomico', title:'Teatro Comico'},
  {id: '13', to: '/entertainment/produzione/mammucari', filename:'mammucari', title:'Teo Mammucari', subtitle:'Live'},
  {id: '14', to: '/entertainment/produzione/trepianogen', filename:'3pianogen', title:'Luis Bacalov, Rita Marcotulli e Alberto Pizzo', subtitle:'3 Piano Generations'},
 
]

const Produzione = () => {
  const theme = useContext(ThemeContext)

  useEffect(() => {
    theme.setBackGroundColor("red")
    theme.setOriginPage({
      label: "Produzione",
      to: "/entertainment/produzione",
    })
    theme.setVisibleFooter(true)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header
        title="Produzione"
        bannerImage="entertainment/produzione-banner"
        metaDescription="ACME organizza e realizza eventi dal vivo, spettacoli live, concerti, produzioni teatrali, balletti, mostre, adatti ad ogni tipologia di pubblico."
      />
      <Description type="onTheLeft">
        <h2>
          ACME organizza e realizza eventi dal vivo, spettacoli live, concerti,
          produzioni teatrali, balletti, mostre, adatti ad ogni tipologia di
          pubblico.
        </h2>
        <p>
          Si occupa della parte finanziaria, manageriale e logistica della
          produzione, e può contribuire al lato creativo del processo di
          realizzazione. Curando anche promozione e supervisione. Dopo l’idea
          creativa viene definito il progetto dell’evento da presentare alle
          istituzioni e agli investitori privati, per coinvolgerli e renderli
          parte integrante dell’appuntamento. La cura dei dettagli, l’entusiasmo,
          la versatilità e un processo di selezione estremamente attento per
          i membri del team e gli esperti, sono i punti di forza ACME; tutte
          queste caratteristiche si ritrovano nel singolo evento facendolo
          diventare un appuntamento unico.
        </p>
      </Description>
      <div className="entertainment">
        <div className="columns">
          {CARDS.map(i => (
            <Card
              key={i.id}
              {...i}
              className="column is-one-third-desktop is-half-tablet is-full-mobile"
              imageComponent={DuotoneImage}
              imageType="negative"
            />
          ))}
        </div>
      </div>
    </>
  )
}

export default Produzione
