import React from "react"
import TechSoftLayout from "../../../components/techSoftLayout/techSoftLayout.js"

const PiacereBarbecue = () => {
  return (
    <TechSoftLayout
      title="Piacere Barbecue"
      descriptionType="customAndWifi"
      metaDescription="Per Piacere Barbecue abbiamo realizzato infrastruttura WiFi e garantito servizi di autenticazione clienti, connessione."
      contents={[{
        date:`Committente: Sedicieventi - Div. Gioform Srl
        Giugno 2015
        Perugia, Area del Percorso Verde Pian di Massiano
        
        `,
        text: `
        Piacere Barbecue è l'evento enogastronomico più amato dell'estate
        perugina! L’evento arricchito nel format e ampliato nel programma,
        si è sviluppato in tre roventi competition, durante le quali si sono
        sfidati team italiani e stranieri.`,
        image: "barbecue.png"
        },
        {
        text: {
            partOne: `Esigenze del cliente

            • Copertura WiFi delle aree evento
            • Rendere estremamente semplice e veloce la procedura di autenticazione per gli ospiti`,
            partTwo: `Le nostre soluzioni
            
            • Realizzazione infrastruttura rete WiFi per stand espositori, area spettacolo e food
            • Fornitura connettività dedicata
            • Captive portal Connect@You per l’autenticazione del pubblico e degli espositori
            • Servizio di assistenza`
          }
        }
      ]}
    />
  )
}

export default PiacereBarbecue
